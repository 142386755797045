import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Select, MenuItem, Modal, Button, Grid, Tooltip, Backdrop, CircularProgress, TextField, InputAdornment, Drawer, Paper } from "@material-ui/core";
import { Add, InfoOutlined } from "@material-ui/icons";
import ColorPicker from "material-ui-color-picker";
import ContactInformationController, { HeaderData, HeaderTemObj, Props, configJSON } from "./ContactInformationController.web";
import IOSSwitch from "../../ss-cms-common-components/src/IOSSwitch/IOSSwitch";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "./styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import AddCropper from "../../ss-cms-common-components/src/AddCropper/AddCropper.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import UploadBox from "../../ss-cms-common-components/src/UploadBox/UploadBox.Web";
import storageProvider from "../../../framework/src/StorageProvider.web";
import { dropUpArrowIcon, checkIcon, ModalSearchIcon, AddSectionIcon, CloseIcon, minusIcon, plusIcon, checkedImg, SectionIcon, deleteIcon, dropDownArrowIcon, uploadIcon, EditIcon, TooltipIcon, editIconBannerText, createIcon, banner1, banner2, banner3, banner4, editButton, bin, SingleCarousel, FullImageBanner, Editbutton, minimalBanner, focussedBanner, extraBanner, staffMinimal, staffFocussed, staffExtra, mergedBanner, mergedFullWidthBanner, staticWhiteFullWidthBanner, croppedBanner, fullWidthBanner, staffMinimalImage,staffFocussedImage } from "./assets";
import { Autocomplete } from "@material-ui/lab";
import { StaffData, StaffDisplay } from "./BrandingController.web";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LockOutlinedIcon from '@material-ui/icons/HttpsOutlined';
// Customizable Area End


class ContactInformation extends ContactInformationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start


        const bannerSelection = [
            {
                bannerImg: banner1,
                name: "Elevate"
            },
            {
                bannerImg: banner2,
                name: "Nexus"
            },
            {
                bannerImg: staticWhiteFullWidthBanner,
                name: "Horizon"
            },
            {
                bannerImg: mergedFullWidthBanner,
                name: "Radiance"
            }
        ]

        const sectionBanner = [
            {
                image: croppedBanner,
                name: "Cropped",
                subTitle: ""
            },
            {
                image: fullWidthBanner,
                name: "Full Width",
                subTitle: ""
            },
            {
                image: banner3,
                name: "Carousel",
                subTitle: ""
            }

        ]
        const serviceSelectionList = [
            {
                image: minimalBanner,
                name: "Minimal",
                subTitle:"Shows the name and price of the service"
            },
            {
                image: focussedBanner,
                name: "Focussed",
                subTitle:"Shows name and price of the service, with the primary card in focus"

            },
            {
                image: extraBanner,
                name: "Extra",
                subTitle:"Shows the name and price of the service"

            },
        ]

        const staffSelectionList = [
            {
                image: staffMinimalImage,
                name: "Minimal",
                subTitle:"Shows the name and role of the staff"
            },
            {
                image: staffFocussedImage,
                name: "Focussed",
                subTitle:"Shows name and role of the staff, with the primary card in focus."

            },
            {
                image: staffExtra,
                name: "Extra",
                subTitle:"Shows the name and role of the staff and description"

            },
        ]


        const RendertemplateModal = (activeItem: number, selectedSection: string) => {
            return (
                <>
                    <CustomHeaderModal disablePortal disableEnforceFocus disableAutoFocus open={this.state.isBannerOpen && isBannerEnable} onClose={() => this.closeModal()}>
                        <Paper className="sectionPaper">
                            <Box style={{ display: "flex", height: "700px" }}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" >
                                        <Grid item xs={12} sm={3}>
                                            <Box className="outerContainer">
                                                <Box className="headingMainContainer">
                                                    <Typography className="addSectionCls">Add new section</Typography>
                                                    <Typography className="subTitleHading">Choose a section and add it to your website</Typography>
                                                </Box>
                                                <Box style={{ display: "flex", flexDirection: "column", padding: "20px" }}>

                                                    {["Banner", "Staff display", "Service display"].map(
                                                        (itemName: string, index: number) => (
                                                            <Box key={index}>
                                                                <Typography className={`popUpOptions ${activeItem === index ? 'isActive' : ''}`}>{itemName}</Typography>
                                                            </Box>
                                                        )
                                                    )}
                                                </Box>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <Box style={{ flex: 1, display: "flex", flexDirection: "column", borderLeft: "1px solid #CBD5E1", height: 'fit-content' }}>
                                                <Box className="mainSection">
                                                    <Box className="modalHeadbox">
                                                        <Typography className="modalHeading"></Typography>
                                                        <Close src={CloseIcon} onClick={() => this.handleCloseBannerModal(selectedSection,0)} style={{ width: "24px", height: "24px" }} />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    style={{
                                                        flex: 1,
                                                        overflowY: "auto",
                                                        height: "calc(700px - 120px)", // Adjust to fit the button height
                                                        padding: "30px",
                                                    }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" justifyContent="flex-start" spacing={6} style={{ padding: '25px' }}>
                                                            {this.state.headerTemplate && this.state.headerTemplate.map((item: HeaderTemObj, index: number) => {
                                                                let selectedSectionName;
                                                                console.log(selectedSection, "selectedSection")
                                                              if (selectedSection === 'banner') {
                                                                    selectedSectionName = sectionBanner;
                                                                } else if (selectedSection === 'staff_display') {
                                                                    selectedSectionName = staffSelectionList;
                                                                } else {
                                                                    selectedSectionName = serviceSelectionList;
                                                                }
                                                                const imgStyle =  
                                                                index === 0
                                                                        ? { width: "415px", height: selectedSection === "service_display" ? "auto" : "190px", objectFit: "cover" } // First image
                                                                        : index === 1
                                                                            ? { width: "415px", height: selectedSection === 'banner' ? "190px": selectedSection === 'staff_display' ? "286px" : "370px", objectFit: "cover" } // Second image
                                                                            : { width: "415px", height: "auto", objectFit: "cover" }; // Default for others
                                                                            return (
                                                                    <>
                                                                        <Grid
                                                                            onClick={() => { this.selectCatalogue(item?.attributes.id) }}
                                                                            item
                                                                            xl={6} lg={6} md={6} sm={12} xs={12}
                                                                            style={{ marginTop: index === 2 ? ((selectedSection !== 'banner' && selectedSection !== 'staff_display') ? "-100px" : "-70px") : "" }}
                                                                            className={this.state.selectedCatalogue === item?.attributes.id ? "selectedCatalogue comingsoonMainContainer" : "comingsoonMainContainer"}
                                                                        >
                                                                            <img src={selectedSectionName[index]?.image} style={imgStyle as React.CSSProperties}  />
                                                                            <Typography className="carousel">{item.attributes.name}</Typography>
                                                                            <Typography className="subTitle">{selectedSectionName[index]?.subTitle}</Typography>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="btnBox">
                                                    <Button
                                                        onClick={() => this.handleCloseBannerModal(selectedSection,0)}
                                                        style={{
                                                            color: "#DC2626",
                                                            textTransform: "none",
                                                            textDecoration: "underline",
                                                            fontWeight: 400,
                                                            fontFamily: "Rubik",
                                                            fontSize: "12px",
                                                            lineHeight: "20px"
                                                        }}
                                                    >
                                                        {configJSON.cancel}
                                                    </Button>
                                                    <Button
                                                        onClick={this.handleAddSectionBanner}
                                                        style={{
                                                            textTransform: "none",
                                                            backgroundColor: "#00D659",
                                                            borderRadius: "0px",
                                                            height: "60px",
                                                            minWidth: "185px",
                                                            fontSize: "16px",
                                                            fontWeight: 500,
                                                            lineHeight: "24px",
                                                            color: "#3C3E49"
                                                        }}
                                                    >
                                                        {configJSON.addSection}
                                                    </Button>
                                                </Box>

                                            </Box>
                                        </Grid>

                                    </Grid>

                                </Grid>




                            </Box>
                        </Paper>
                    </CustomHeaderModal>

                </>
            )
        }


        const headerName = this.props?.parentStates?.updateData?.header?.templateDetails?.name;
        storageProvider.set("headerName", headerName);
        let headerImageURL = banner1;
        if(headerName?.trim() === "Merged with banner") {
          headerImageURL = mergedBanner ;
       } else if(headerName === "Static White with full width banner") {
          headerImageURL = staticWhiteFullWidthBanner;
       } else if(headerName === "Merged  with full width banner") {
         headerImageURL = mergedFullWidthBanner ;
       } 

        const selectedHeaderSection: HeaderData | undefined = bannerSelection.find((item) => item.name == headerName);
        const { isHeaderEnable, isBannerEnable } = this.props.parentStates;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ContentBar>
                    <ContentButtonBar data-test-id={"ThemeFontsSectionId"} onClick={this.toggleThemeFontsContent}>
                        <ContentHeadinText style={{ color: this.state.themeFontsContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Theme & Fonts
                        </ContentHeadinText>
                        <img src={this.state.themeFontsContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.themeFontsContent && (
                        <>
                            <SectionContentDropDownBox>
                                <SectionDropDownBox onClick={this.toggleColorSelectionOpen}>
                                    <SectionDropDownText>Colour Selection</SectionDropDownText>
                                    <SectionDropDownIcon src={this.state.isColorSelectionOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                </SectionDropDownBox>
                                {this.state.isColorSelectionOpen &&
                                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '20px 10px 30px 20px', justifyContent: 'flex-end' }}>
                                        {this.state.defaultColors?.map((color) => {
                                            const borderProperties = this.state.selectedColor.id === color.id ? '1px solid #6200EA' : '1px solid #D0D2DA'
                                            return (
                                                <div style={{ width: 'fit-content', height: "48px", border: borderProperties, borderRadius: "4px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={color.id} onClick={() => this.handleColorSelection(color)}>
                                                    <div style={{ backgroundColor: color.primary, width: "36px", height: "36px", borderRadius: '50%', transform: 'translateX(7px)' }} />
                                                    <div style={{ backgroundColor: color.secondary, width: "36px", height: "36px", borderRadius: '50%', transform: 'translateX(-7px)' }} />
                                                </div>
                                            )
                                        })}
                                        <div style={{ minWidth: "74px", width: 'fit-content', height: "48px", border: !this.state.selectedColor.id ? '1px solid #6200EA' : '1px solid #D0D2DA', borderRadius: "4px", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={this.handleCustomColorSelection}>
                                            <Add style={{ color: '#000', fontSize: "28px", marginBottom: "-6px" }} />
                                            <span style={{ fontSize: "10px", color: '#676B7E' }}>Custom</span>
                                        </div>
                                        {!this.state.selectedColor.id && (
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '94%' }} >
                                                    <label style={{ marginBottom: "6px", display: 'flex', alignItems: 'center', color: '#3C3E49', fontWeight: 500, fontSize: "14px" }}>Primary <InfoOutlined style={{ fontSize: "18px", color: '#3C3E49', marginLeft: "3px" }} /></label>
                                                    <ColorPicker
                                                        variant="outlined"
                                                        onChange={this.handlePrimaryColorChange}
                                                        hintText={this.state.selectedColor.primary}
                                                        value={this.state.selectedColor.primary}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <section
                                                                        style={{
                                                                            background: this.state.selectedColor.primary,
                                                                            cursor: "pointer",
                                                                            height: "36px",
                                                                            width: "36px",
                                                                            borderRadius: "50%"
                                                                        }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            inputProps: {
                                                                style: {
                                                                    color: '#475569',
                                                                    fontSize: "14px",
                                                                    textTransform: 'uppercase',
                                                                    opacity: 1,
                                                                    fontWeight: 500
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '94%' }} >
                                                    <label style={{ marginBottom: "6px", display: 'flex', alignItems: 'center', color: '#3C3E49', fontWeight: 500, fontSize: "14px" }}>Secondary <InfoOutlined style={{ fontSize: "18px", color: '#3C3E49', marginLeft: "3px" }} /></label>
                                                    <ColorPicker
                                                        variant="outlined"
                                                        onChange={this.handleSecondaryColorChange}
                                                        hintText={this.state.selectedColor.secondary}
                                                        value={this.state.selectedColor.secondary}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <section
                                                                        style={{
                                                                            background: this.state.selectedColor.secondary,
                                                                            cursor: "pointer",
                                                                            height: "36px",
                                                                            width: "36px",
                                                                            borderRadius: "50%"
                                                                        }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            inputProps: {
                                                                style: {
                                                                    color: '#475569',
                                                                    fontSize: "14px",
                                                                    textTransform: 'uppercase',
                                                                    opacity: 1,
                                                                    fontWeight: 500
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </>)}
                                    </div>
                                }
                            </SectionContentDropDownBox>
                            <SectionContentDropDownBox>
                                <SectionDropDownBox onClick={this.toggleFontSelectionOpen}>
                                    <SectionDropDownText>Fonts</SectionDropDownText>
                                    <SectionDropDownIcon src={this.state.isFontSelectionOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                </SectionDropDownBox>
                            </SectionContentDropDownBox>
                            {this.state.isFontSelectionOpen &&
                                <div style={{ padding: '20px 0' }}>
                                    <SectionContentDropDown>
                                        <SectionDropDownHeading>Header Text</SectionDropDownHeading>
                                        <SectionSelectSubBox
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                keepMounted: true
                                            }}
                                            displayEmpty
                                            value={this.state.selectedFonts.headerText}
                                            onChange={(event) => this.handleHeaderTextFontChange(event.target.value as string)}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <DisabledMenuItem value="" disabled>Select Font</DisabledMenuItem>
                                            {configJSON.fontsList.map((font: string, index: number) => {
                                                return (
                                                    <CustomMenuItem value={`${font}, "sans-serif"`} key={index}>{font}</CustomMenuItem>
                                                )
                                            })}
                                        </SectionSelectSubBox>
                                        <SectionDropDownHeading>Body Text</SectionDropDownHeading>
                                        <SectionSelectSubBox
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                keepMounted: true
                                            }}
                                            displayEmpty
                                            value={this.state.selectedFonts.bodyText}
                                            onChange={(event) => this.handleBodyTextFontChange(event.target.value as string)}
                                        >
                                            <DisabledMenuItem value="" disabled>Select Font</DisabledMenuItem>
                                            {configJSON.fontsList.map((font: string, index: number) => {
                                                return (
                                                    <CustomMenuItem value={`${font}, sans-serif`} key={index}>{font}</CustomMenuItem>
                                                )
                                            })}
                                        </SectionSelectSubBox>
                                    </SectionContentDropDown>
                                </div>
                            }
                        </>
                    )}
                    <ContentButtonBar data-test-id="HeaderToggle" onClick={this.toggleHeaderContent}>
                        <ContentHeadinText style={{ color: this.state.headerContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Header
                        </ContentHeadinText>
                        <img src={this.state.headerContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.headerContent && (
                        <FooterContentOuterBox>

                            {isHeaderEnable &&
                                <CustomizeHeader>
                                    <Typography className="customHeaderText">{configJSON.customHeader}</Typography>
                                    <Typography className="customBodyText">{configJSON.customBody}</Typography>
                                    {selectedHeaderSection ?
                                        <>
                                            <Box className="imageBox">
                                                <img src={editButton} onClick={this.handleEditHeader} alt="editBtn" className="editBtn" />
                                                <img src={selectedHeaderSection?.bannerImg} alt="banner1" width={"100%"} height={"100%"} />                                 
                                            </Box>
                                            <Typography className="bannerName">{selectedHeaderSection?.name}</Typography>
                                        </>
                                        :
                                        <>
                                            <Box className="imageBox">
                                                <img src={editButton} onClick={this.handleEditHeader} alt="editBtn" className="editBtn" />
                                                <img src={headerImageURL} alt="banner1" width={"100%"} height={"100%"} />
                                            </Box>
                                            <Typography className="bannerName">Elevent</Typography>
                                        </>
                                    }
                                </CustomizeHeader>
                            }

                            <HeaderBoxContent>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>
                                        Show Store Name
                                    </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="checkedB"
                                        onChange={this.toggleStorenameForHeader}
                                        isDisabled={!this.props?.parentStates.updateData?.header?.isLogoUploaded || this.props.parentStates.removeLogo || !this.props.parentStates.storeName}
                                        checked={(((this.props?.parentStates.updateData?.header?.isLogoUploaded && this.state.showStoreName) || this.props.parentStates.removeLogo) && this.props.parentStates.storeName) && !(!this.props?.parentStates.updateData?.header?.isLogoUploaded || this.props.parentStates.removeLogo || !this.props.parentStates.storeName)} data-testid={""} />
                                </SocialMediaHeaderBox>
                                <HeaderDropDownSubHeading>Logo</HeaderDropDownSubHeading>
                                <UploadButtonBox style={{ margin: '0 10px 0 20px', width: 'auto' }} data-test-id="ToggleLogoUploadModal" onClick={() => this.HandleLogoUploadModalOpen("logo")}>
                                    {/* <InputFieldText>{this.state.isLogoUploaded ? this.state.SelectedLogoName : "Upload Logo"}</InputFieldText>
                                    <UploadIcon src={!this.state.isLogoUploaded ? uploadIcon : EditIcon} /> */}
                                    <InputFieldText>{(!this.props.parentStates.removeLogo && this.state.isLogoUploaded) ? this.state.SelectedLogoName : this.props.parentStates.storeName}</InputFieldText>
                                    <UploadIcon src={EditIcon} />
                                </UploadButtonBox>
                                <HeaderDropDownSubHeading>
                                    Favicon
                                    <Tooltip TransitionProps={{ timeout: 600 }} title={configJSON.FaviconTooltipText} arrow placement="top">
                                        <img src={TooltipIcon} alt="" style={{ height: '18px', width: '18px' }} />
                                    </Tooltip>
                                </HeaderDropDownSubHeading>
                                <UploadButtonBox style={{ margin: '0 10px 0 20px', width: 'auto' }} data-test-id="ToggleFaviconUploadModal" onClick={() => this.HandleLogoUploadModalOpen("favicon")}>
                                    <InputFieldText>{this.state.isCustomFaviconSelected ? this.state.SelectedFaviconName : this.props.storeName.substring(0, 1)}</InputFieldText>
                                    <UploadIcon src={!this.state.isFaviconUploaded ? uploadIcon : EditIcon} />

                                </UploadButtonBox>
                            </HeaderBoxContent>
                            <Box sx={{ padding: '10px 0 10px 30px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px 0px' }} style={{ gap: '5px' }}>
                                    <FooterContentHeadingText>
                                        Navigation Items
                                    </FooterContentHeadingText>
                                    <Box sx={{ fontSize: '12px', fontWeight: 400 }}>
                                        <span style={{ fontFamily: 'Rubik' }}>{configJSON.HeaderNavigationSettingsMessage}</span>
                                    </Box>
                                </Box>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>
                                        Open Mobile Menu
                                    </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="checkedB"
                                        onChange={this.toggleMobileMenuForHeader}
                                        checked={this.state.isMobileMenuOpen} data-testid={""} />
                                </SocialMediaHeaderBox>
                                <Box>

                                    <HeaderDropDownSubHeading>
                                        {configJSON.NavigationItemOneText}
                                    </HeaderDropDownSubHeading>
                                    <InputTextFieldBox
                                        type="text"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    <UploadIcon src={createIcon} />
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="outlined"
                                        value={this.state.navigationItems.nevigationItem1}
                                        onChange={this.handleNavigationItemOne}
                                        style={{ margin: '5px 20px' }}
                                        data-test-id="ToggleFaviconUploadModal1"
                                    />

                                    <HeaderDropDownSubHeading>
                                        {configJSON.NavigationItemTwoText}
                                    </HeaderDropDownSubHeading>
                                    <InputTextFieldBox
                                        type="text"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    <UploadIcon src={createIcon} />
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="outlined"
                                        value={this.state.navigationItems.nevigationItem2}
                                        onChange={this.handleNavigationItemTwo}
                                        style={{ margin: '5px 20px' }}
                                        data-test-id="ToggleFaviconUploadModal2"
                                    />

                                    <HeaderDropDownSubHeading>
                                        {configJSON.NavigationItemThreeText}
                                    </HeaderDropDownSubHeading>
                                    <InputTextFieldBox
                                        type="text"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    <UploadIcon src={createIcon} />
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="outlined"
                                        value={this.state.navigationItems.nevigationItem3}
                                        onChange={this.handleNavigationItemThree}
                                        style={{ margin: '5px 20px' }}
                                        data-test-id="ToggleFaviconUploadModal3"
                                    />
                                </Box>
                            </Box>
                        </FooterContentOuterBox>
                    )}
                    <ContentButtonBar data-test-id={"SectionButtonId"} onClick={this.toggleSectionContent}>
                        <ContentHeadinText style={{ color: this.state.sectionContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Sections
                        </ContentHeadinText>
                        <img src={this.state.sectionContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.sectionContent && (
                        <>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable-sections">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {
                                                this.props.parentStates.SectionData.map((sectionItem, index: number, arr) => {
                                                    const { sectionName, type, id, metadata, is_deleted, bannerBackgroundColour, bannerPosition, templateDetails } = sectionItem
                                                    const indexx = arr?.filter(a => !a.is_deleted)?.map((item, indexVal) => ({ id: item.id, index: indexVal }))
                                                    const slectedServiceTheme: any = serviceSelectionList.find((item) => item.name == templateDetails?.name || "");
                                                    const slectedStaffTheme: any = staffSelectionList.find((item) => item.name == templateDetails?.name || "");
                                                    
                                                    return (
                                                        !is_deleted && (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.draggableProps} >
                                                                        {
                                                                            type?.toLowerCase() === "banner" &&
                                                                            <SectionContentDropDownBox key={index}>
                                                                                <SectionSelectIcon src={SectionIcon} {...provided.dragHandleProps} />
                                                                                <SectionDropDownBox data-test-id={"BannerDropDownTestId"} onClick={() => this.toggleDropDown(index, true)}>
                                                                                    <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj?.id == id) + 1} - Banner`}</SectionDropDownText>
                                                                                    <DeleteBox>
                                                                                        <DeleteButtonSectionBox>
                                                                                            <DeleteSectionButton onClick={() => this.openDeleteSectionModal(index, { section_name: `section${index + 1}`, section_type: "banner", is_Deleted: true, id })}>
                                                                                                <DeleteButtonIcon src={bin} />
                                                                                            </DeleteSectionButton>
                                                                                        </DeleteButtonSectionBox>
                                                                                        <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                                                    </DeleteBox>
                                                                                </SectionDropDownBox>
                                                                                {
                                                                                    metadata?.isOpen &&
                                                                                    <SectionContentDropDown>
                                                                                        {
                                                                                            isBannerEnable &&
                                                                                            <BannerPreview>
                                                                                                <EditImageIcon onClick={() => this.handleCloseBannerModal('banner')}>
                                                                                                    <img src={Editbutton} width={"27px"} height={"27px"} />
                                                                                                </EditImageIcon>
                                                                                                <img src={templateDetails?.name === "Full Width" ? FullImageBanner : SingleCarousel} width={"320px"} height={"150px"} />
                                                                                            </BannerPreview>
                                                                                        }
                                                                                        <SectionDropDownHeading data-test-id={"BannerTextButtonId"}> Type <RedStar component={"span"}>*</RedStar></SectionDropDownHeading>
                                                                                        <SectionSelectSubBox
                                                                                            MenuProps={{
                                                                                                anchorOrigin: {
                                                                                                    vertical: "bottom",
                                                                                                    horizontal: "left"
                                                                                                },
                                                                                                transformOrigin: {
                                                                                                    vertical: "top",
                                                                                                    horizontal: "left"
                                                                                                },
                                                                                                getContentAnchorEl: null,
                                                                                                keepMounted: true
                                                                                            }}
                                                                                            onChange={(event) => this.typeSelectHandler(index, event.target.value as string)}
                                                                                            displayEmpty
                                                                                            value={type}
                                                                                        >
                                                                                            <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                                                                            <CustomMenuItem value="banner">Banner</CustomMenuItem>
                                                                                            <CustomMenuItem value="staff_display">Staff Display</CustomMenuItem>
                                                                                            <CustomMenuItem value="service_display">Service Display</CustomMenuItem>
                                                                                        </SectionSelectSubBox>

                                                                                        <SectionDropDownSubHeading data-test-id={"uploadDesktopText"}>{configJSON.bannerBackColor}</SectionDropDownSubHeading>
                                                                                        <ColorPicker
                                                                                            variant="outlined"
                                                                                            onChange={(event) => this.handleBackgroundColorChange(event as string, index)}
                                                                                            hintText={(bannerBackgroundColour == "null" || bannerBackgroundColour == null) ? "" : bannerBackgroundColour}
                                                                                            value={(bannerBackgroundColour == "null" || bannerBackgroundColour == null) ? "" : bannerBackgroundColour}
                                                                                            style={webStyle.colorPattel}
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment position="end">
                                                                                                        <section
                                                                                                            style={{
                                                                                                                background: bannerBackgroundColour == "null" || bannerBackgroundColour == null ? "" : bannerBackgroundColour,
                                                                                                                cursor: "pointer",
                                                                                                                height: "36px",
                                                                                                                width: "36px",
                                                                                                                borderRadius: "50%"
                                                                                                            }}
                                                                                                        />
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                                inputProps: {
                                                                                                    style: {
                                                                                                        color: '#475569',
                                                                                                        fontSize: "14px",
                                                                                                        textTransform: 'uppercase',
                                                                                                        opacity: 1,
                                                                                                        fontWeight: 500
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />

                                                                                        <SectionDropDownSubHeading data-test-id={"uploadDesktopText"}>{configJSON.bannerImage} <RedStar component={"span"}></RedStar></SectionDropDownSubHeading>
                                                                                        <UploadButtonBox onClick={() => this.handleUploadModal(this.props.parentStates.selectedTab, index, id)}>
                                                                                            <InputFieldText>
                                                                                                {sectionItem.desktopBannerVariants?.desktop_banner_variant_1920 && sectionItem.desktopBannerName ?
                                                                                                    sectionItem.desktopBannerName : "Upload Banner"}
                                                                                            </InputFieldText>
                                                                                            <UploadIcon data-test-id={"BannerDesktopTestId"} src={
                                                                                                sectionItem.desktopBannerUpload && sectionItem.desktopBannerName ?
                                                                                                    EditIcon : uploadIcon} />
                                                                                        </UploadButtonBox>
                                                                                        {/* {sectionItem.desktopBannerUpload == "" && (<ErrorText>{configJSON.imageValidationText}</ErrorText>)} */}
                                                                                        {
                                                                                            this.props.parentStates.selectedTab === "mobile" &&
                                                                                            <AddSectionButtonBoxex data-test-id={"addSectionButtonId"} onClick={() => this.toggleAddTextToBannerDrawer(true, "mobile")} style={webStyle.addSection} >
                                                                                                <AddSectionButtonIcon src={!this.state.bannerTextProperties.mobile.textAlignment ? AddSectionIcon : editIconBannerText} />
                                                                                                <AddSectionButtonText data-test-id="addSectionText">
                                                                                                    {this.state.bannerTextProperties.mobile.textAlignment ? "Edit" : "Add"} {configJSON.bannerTextMobile}
                                                                                                </AddSectionButtonText>
                                                                                            </AddSectionButtonBoxex>
                                                                                        }
                                                                                        {
                                                                                            this.props.parentStates.selectedTab === "desktop" &&
                                                                                            <AddSectionButtonBoxex data-test-id={"addSectionButtonId"} onClick={() => this.toggleAddTextToBannerDrawer(true, "desktop")} style={webStyle.addSection} >
                                                                                                <AddSectionButtonIcon src={!this.state.bannerTextProperties.desktop.textAlignment ? AddSectionIcon : editIconBannerText} />
                                                                                                <AddSectionButtonText data-test-id="addSectionText">
                                                                                                    {this.state.bannerTextProperties.desktop.headerText ? "Edit" : "Add"} {configJSON.bannerText}
                                                                                                </AddSectionButtonText>
                                                                                            </AddSectionButtonBoxex>
                                                                                        }

                                                                                        {
                                                                                            this.props.parentStates.selectedTab === "desktop" &&
                                                                                            <>
                                                                                                <SectionDropDownSubHeading data-test-id={"BannerTextButtonId"}>{configJSON.bannerPosition}</SectionDropDownSubHeading>
                                                                                                <SectionSelectSubBox
                                                                                                    MenuProps={{
                                                                                                        anchorOrigin: {
                                                                                                            vertical: "bottom",
                                                                                                            horizontal: "left"
                                                                                                        },
                                                                                                        transformOrigin: {
                                                                                                            vertical: "top",
                                                                                                            horizontal: "left"
                                                                                                        },
                                                                                                        getContentAnchorEl: null,
                                                                                                        keepMounted: true
                                                                                                    }}
                                                                                                    onChange={(event) => this.hanldeBannerPosition(event.target.value as string, index)}
                                                                                                    displayEmpty
                                                                                                    value={bannerPosition}
                                                                                                >
                                                                                                    <DisabledMenuItem value="" disabled>{configJSON.selectBannerPosition}</DisabledMenuItem>
                                                                                                    <CustomMenuItem value="left">{configJSON.left}</CustomMenuItem>
                                                                                                    <CustomMenuItem value="right">{configJSON.right}</CustomMenuItem>
                                                                                                </SectionSelectSubBox>
                                                                                            </>
                                                                                        }

                                                                                        <SectionDropDownSubHeading> Banner URL <RedStar component={"span"}></RedStar></SectionDropDownSubHeading>
                                                                                        <SectionInputSubBox name="banner_url" data-test-id={"BannerChangeTestId"} type="text" value={sectionItem.bannerUrl} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleBannerUrl(e, id, "banner", `section${index + 1}`, index)} placeholder="www.testurl.com" />
                                                                                        {this.state.checkBannerUrl && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                                                                    </SectionContentDropDown>
                                                                                }
                                                                            </SectionContentDropDownBox>
                                                                        }
                                                                        {
                                                                            (type?.toLowerCase() === "service_display") &&
                                                                            <SectionContentDropDownBox key={index}>
                                                                                <SectionSelectIcon src={SectionIcon} {...provided.dragHandleProps} />
                                                                                <SectionDropDownBox onClick={() => this.toggleDropDown(index, true)}>
                                                                                    <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj.id == id) + 1} - Service Display`}</SectionDropDownText>
                                                                                    <DeleteBox>
                                                                                        <DeleteButtonSectionBox>
                                                                                            <DeleteSectionButton onClick={() => this.openDeleteSectionModal(index, { section_name: `section${index + 1}`, section_type: "service_display", is_Deleted: true, id })}>
                                                                                                <DeleteButtonIcon src={bin} />
                                                                                            </DeleteSectionButton>
                                                                                        </DeleteButtonSectionBox>
                                                                                        <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                                                    </DeleteBox>
                                                                                </SectionDropDownBox>
                                                                                {
                                                                                    metadata?.isOpen ?
                                                                                        <SectionContentDropDown>
                                                                                            {isBannerEnable&&
                                                                                            <>
                                                                                            {slectedServiceTheme ?
                                                                                                <>
                                                                                                    <BannerPreview>
                                                                                                        <EditImageIcon onClick={() => this.handleCloseBannerModal('service_display',templateDetails?.id)} >
                                                                                                            <img src={Editbutton} width={"27px"} height={"27px"} />
                                                                                                        </EditImageIcon>
                                                                                                        <img src={slectedServiceTheme.image} width={"320px"} height={"150px"} />
                                                                                                    </BannerPreview>
                                                                                                    <Typography className="bannerName">{slectedServiceTheme.name}</Typography>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <BannerPreview>
                                                                                                        <EditImageIcon onClick={() => this.handleCloseBannerModal('service_display')}>
                                                                                                            <img src={Editbutton} width={"27px"} height={"27px"} />
                                                                                                        </EditImageIcon>
                                                                                                        <img src={minimalBanner} width={"320px"} height={"150px"} />
                                                                                                    </BannerPreview>
                                                                                                    <Typography className="bannerName">Minimal</Typography>
                                                                                                </>
                                                                                            }
                                                                                            </>
                                                                                            
                                                                                            
                                                                                        }
                                                                                            

                                                                                            <SectionDropDownHeading> Type </SectionDropDownHeading>
                                                                                            <SectionSelectSubBox
                                                                                                MenuProps={{
                                                                                                    anchorOrigin: {
                                                                                                        vertical: "bottom",
                                                                                                        horizontal: "left"
                                                                                                    },
                                                                                                    transformOrigin: {
                                                                                                        vertical: "top",
                                                                                                        horizontal: "left"
                                                                                                    },
                                                                                                    getContentAnchorEl: null,
                                                                                                    keepMounted: true
                                                                                                }}
                                                                                                onChange={(event) => this.typeSelectHandler(index, event.target.value as string)}
                                                                                                displayEmpty
                                                                                                value={type}
                                                                                            >
                                                                                                <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                                                                                <CustomMenuItem value="staff_display">Staff Display</CustomMenuItem>
                                                                                                <CustomMenuItem value="banner">Banner</CustomMenuItem>
                                                                                                <CustomMenuItem value="service_display">Service Display</CustomMenuItem>
                                                                                            </SectionSelectSubBox>
                                                                                            <SectionDropDownSubHeading> Section Name <RedStar component={"span"}></RedStar></SectionDropDownSubHeading>
                                                                                            <UploadButtonBox>
                                                                                                <SectionInputNameBox placeholder="Section Name" value={sectionName} onChange={(event) => this.changeSectionName(event, id.toString(), index, `section${index + 1}`, "service_display")} />
                                                                                            </UploadButtonBox>
                                                                                            {metadata?.services?.map((item: any, index: number) => {
                                                                                               
                                                                                                return (
                                                                                                    <>
                                                                                                        <SectionDropDownSubHeading> Service {index + 1} </SectionDropDownSubHeading>
                                                                                                        <SectionAutoCompleteSubBox
                                                                                                            options={this.props.parentStates.servicesData}
                                                                                                            getOptionLabel={(serviceItem: any) => serviceItem?.attributes?.title}
                                                                                                            value={
                                                                                                                this.props.parentStates.servicesData.find(
                                                                                                                    (serviceItem: any) => serviceItem.id == item?.selected
                                                                                                                ) || null
                                                                                                            }
                                                                                                            onChange={(_, value: any) => this.handleSectionMainData(id, type, `section${index + 1}`, `service${index + 1}`, value?.id as string, index)}
                                                                                                            renderInput={(params) => (
                                                                                                                <TextField
                                                                                                                    {...params}
                                                                                                                    name="service1"
                                                                                                                    variant="outlined"
                                                                                                                    placeholder="Select Service"
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                        </SectionContentDropDown>
                                                                                        : null
                                                                                }
                                                                            </SectionContentDropDownBox>
                                                                        }
                                                                        {
                                                                            (type?.toLowerCase() === "staff_display") &&
                                                                            <SectionContentDropDownBox key={index}>
                                                                                <SectionSelectIcon src={SectionIcon} {...provided.dragHandleProps} />
                                                                                <SectionDropDownBox onClick={() => this.toggleDropDown(index, true)}>
                                                                                    <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj.id == id) + 1} - Staff Display`}</SectionDropDownText>
                                                                                    <DeleteBox>
                                                                                        <DeleteButtonSectionBox>
                                                                                            <DeleteSectionButton onClick={() => this.openDeleteSectionModal(index, { section_name: `section${index + 1}`, section_type: "staff_display", is_Deleted: true, id })}>
                                                                                                <DeleteButtonIcon src={bin} />
                                                                                            </DeleteSectionButton>
                                                                                        </DeleteButtonSectionBox>
                                                                                        <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                                                    </DeleteBox>
                                                                                </SectionDropDownBox>
                                                                                {
                                                                                    metadata?.isOpen ?
                                                                                        <SectionContentDropDown>
                                                                                            {isBannerEnable &&
                                                                                                <>
                                                                                                    {slectedStaffTheme ?
                                                                                                        <>
                                                                                                            <BannerPreview>
                                                                                                                <EditImageIcon onClick={() => this.handleCloseBannerModal('staff_display', templateDetails?.id)} >
                                                                                                                    <img src={Editbutton} width={"27px"} height={"27px"} />
                                                                                                                </EditImageIcon>
                                                                                                                <img src={slectedStaffTheme.image} width={"320px"} height={"150px"} />
                                                                                                            </BannerPreview>
                                                                                                            <Typography className="bannerName">{slectedStaffTheme.name}</Typography>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <BannerPreview>
                                                                                                                <EditImageIcon onClick={() => this.handleCloseBannerModal('staff_display')}>
                                                                                                                    <img src={Editbutton} width={"27px"} height={"27px"} />
                                                                                                                </EditImageIcon>
                                                                                                                <img src={staffMinimal} width={"320px"} height={"150px"} />
                                                                                                            </BannerPreview>
                                                                                                            <Typography className="bannerName">Minimal</Typography>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                            <SectionDropDownHeading> Type </SectionDropDownHeading>
                                                                                            <SectionSelectSubBox
                                                                                                MenuProps={{
                                                                                                    anchorOrigin: {
                                                                                                        vertical: "bottom",
                                                                                                        horizontal: "left"
                                                                                                    },
                                                                                                    transformOrigin: {
                                                                                                        vertical: "top",
                                                                                                        horizontal: "left"
                                                                                                    },
                                                                                                    getContentAnchorEl: null,
                                                                                                    keepMounted: true
                                                                                                }}
                                                                                                onChange={(event) => this.typeSelectHandler(index, event.target.value as string)}
                                                                                                displayEmpty
                                                                                                value={type}
                                                                                            >
                                                                                                <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                                                                                <CustomMenuItem value="staff_display">Staff Display</CustomMenuItem>
                                                                                                <CustomMenuItem value="banner">Banner</CustomMenuItem>
                                                                                                <CustomMenuItem value="service_display">Service Display</CustomMenuItem>
                                                                                            </SectionSelectSubBox>
                                                                                            
                                                                                            <SectionDropDownSubHeading> Section Name <RedStar component={"span"}></RedStar></SectionDropDownSubHeading>
                                                                                            <UploadButtonBox>
                                                                                                <SectionInputNameBox placeholder="Section Name" value={sectionName} onChange={(event) => this.changeSectionName(event, id.toString(), index, `section${index + 1}`, "service_display")} />
                                                                                            </UploadButtonBox>
                                                                                            {metadata?.staff?.map((item: StaffDisplay, index: number) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <SectionDropDownSubHeading> Stylist {index + 1} </SectionDropDownSubHeading>
                                                                                                        <SectionAutoCompleteSubBox
                                                                                                            options={this.props.parentStates.staffData}
                                                                                                            getOptionLabel={(staffItem) => {
                                                                                                                const selectedValue = staffItem as StaffData;
                                                                                                                return selectedValue?.attributes?.full_name
                                                                                                            }}
                                                                                                            value={
                                                                                                                this.props.parentStates.staffData.find(
                                                                                                                    (staffItem: StaffData) => staffItem.id == item?.selected
                                                                                                                ) || null
                                                                                                            }
                                                                                                            onChange={(_, value) => {
                                                                                                                const selectedValue = value as StaffData | null;
                                                                                                                this.handleSectionMainData(id, type, `section${index + 1}`, `staff${index + 1}`, selectedValue?.id as string, index)
                                                                                                            }}
                                                                                                            renderInput={(params) => (
                                                                                                                <TextField
                                                                                                                    {...params}
                                                                                                                    name="staff1"
                                                                                                                    variant="outlined"
                                                                                                                    placeholder="Select Staff"
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                        </SectionContentDropDown>
                                                                                        : null
                                                                                }
                                                                            </SectionContentDropDownBox>
                                                                        }
                                                                        {
                                                                            (type?.toLowerCase() === "testimonial") &&
                                                                            <SectionContentDropDownBox key={index}>
                                                                                <SectionSelectIcon src={SectionIcon} {...provided.dragHandleProps} />
                                                                                <SectionDropDownBox onClick={() => this.toggleDropDown(index)}>
                                                                                    <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj.id == id) + 1} - Testimonials`}</SectionDropDownText>
                                                                                    <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                                                </SectionDropDownBox>
                                                                                {
                                                                                    metadata?.isOpen &&
                                                                                    <SectionContentDropDown>
                                                                                        <SectionDropDownSubHeading> {configJSON.sectionName} <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                                                        <InputTextFieldBox
                                                                                            variant="outlined"
                                                                                            type="text"
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment
                                                                                                        position="end"
                                                                                                    >
                                                                                                        <UploadIcon src={createIcon} />
                                                                                                    </InputAdornment>
                                                                                                )
                                                                                            }}
                                                                                            placeholder="Section Name"
                                                                                            value={sectionName}
                                                                                            error={this.testimonialErrorCheck(sectionName)}
                                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeSectionName(event, id.toString(), index, `section${index + 1}`, "testimonial")}
                                                                                        />
                                                                                        <TestimonialContainer>
                                                                                            <TestimonialTextBox>
                                                                                                <TestimonialText>
                                                                                                    {configJSON.displayTestimonials}
                                                                                                </TestimonialText>
                                                                                            </TestimonialTextBox>
                                                                                            <Box>
                                                                                                <IOSSwitch
                                                                                                    name="checkedB"
                                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.toggleTestimonial(event, index)}
                                                                                                    checked={metadata.isTestimonial}
                                                                                                />
                                                                                            </Box>
                                                                                        </TestimonialContainer>
                                                                                    </SectionContentDropDown>
                                                                                }
                                                                            </SectionContentDropDownBox>
                                                                        }
                                                                        {
                                                                            (type?.toLowerCase() === "location") &&
                                                                            <SectionContentDropDownBox key={index}>
                                                                                <SectionSelectIcon src={SectionIcon} {...provided.dragHandleProps} />
                                                                                <SectionDropDownBox onClick={() => this.toggleDropDown(index)}>
                                                                                    <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj.id == id) + 1} - Location`}</SectionDropDownText>
                                                                                    <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                                                </SectionDropDownBox>
                                                                                {
                                                                                    metadata?.isOpen &&
                                                                                    <SectionContentDropDown>
                                                                                        <SectionDropDownSubHeading> {configJSON.sectionName} <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                                                        <InputTextFieldBox
                                                                                            variant="outlined"
                                                                                            type="text"
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment
                                                                                                        position="end"
                                                                                                    >
                                                                                                        <UploadIcon src={createIcon} />
                                                                                                    </InputAdornment>
                                                                                                )
                                                                                            }}
                                                                                            placeholder="Section Name"
                                                                                            value={sectionName}
                                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeSectionName(event, id.toString(), index, `section${index + 1}`, "location")}
                                                                                        />
                                                                                        <TestimonialContainer>
                                                                                            <TestimonialTextBox>
                                                                                                <TestimonialText>
                                                                                                    {configJSON.displayLocation}
                                                                                                </TestimonialText>
                                                                                            </TestimonialTextBox>
                                                                                            <Box>
                                                                                                <IOSSwitch
                                                                                                    name="checkedB"
                                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.toggleLocation(event, index)}
                                                                                                    checked={metadata.isLocation}
                                                                                                />
                                                                                            </Box>
                                                                                        </TestimonialContainer>
                                                                                    </SectionContentDropDown>
                                                                                }
                                                                            </SectionContentDropDownBox>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    );
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {
                                this.state.addSectionBox &&
                                <SectionContentDropDownBox>
                                    <SectionSelectIcon src={SectionIcon} />
                                    <SectionDropDownBox>
                                        <SectionDropDownText>Section {this.props.parentStates.SectionData.length > 0
                                            ? this.props.parentStates.SectionData.filter(section => !section.is_deleted).length + 1
                                            : 1}</SectionDropDownText>
                                        <SectionDropDownIcon src={dropDownArrowIcon} />
                                    </SectionDropDownBox>
                                    <SectionContentDropDown>
                                        <DeleteButtonSectionBox>
                                            <DeleteSectionButton>
                                                <DeleteButtonIcon data-test-id={"removeSectionButtonId"} src={deleteIcon} onClick={this.removeAddSectionBox} />
                                            </DeleteSectionButton>
                                        </DeleteButtonSectionBox>
                                        <SectionDropDownHeading> Type <RedStar component={"span"}>*</RedStar></SectionDropDownHeading>
                                        <SectionSelectSubBox
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                keepMounted: true
                                            }}
                                            displayEmpty
                                            value={this.state.selectAddSectionType}
                                            onChange={(event) => this.addNewSection(event, this.props.parentStates.SectionData.filter(section => !section.is_deleted).length)}
                                            data-test-id={"selectAddSectionTypeId"}
                                        >
                                            <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                            <CustomMenuItem data-test-id="SectionText" value="banner">Banner</CustomMenuItem>
                                            <CustomMenuItem value="staff_display">Staff Display</CustomMenuItem>
                                            && <CustomMenuItem value="service_display">Service Display</CustomMenuItem>
                                        </SectionSelectSubBox>
                                    </SectionContentDropDown>
                                </SectionContentDropDownBox>
                            }
                            <AddSectionButtonBox data-test-id={"addSectionButtonId"} onClick={() => this.addSection()}>
                                <AddSectionButtonIcon src={AddSectionIcon} />
                                <AddSectionButtonText data-test-id="addSectionText">Add Section</AddSectionButtonText>
                            </AddSectionButtonBox>
                        </>
                    )}
                    <ContentButtonBar onClick={this.toggleFooterContent} data-test-id={"ContactInformationButtonId"}>
                        <ContentHeadinText style={{ color: this.state.footerContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Footer
                        </ContentHeadinText>
                        <img src={this.state.footerContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.footerContent && (
                        <FooterContentOuterBox>
                            <FooterBox>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText data-test-id={"DisplayTextButtonId"}>
                                        Contact Information
                                    </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="is_contact"
                                        onChange={this.toggleContactInformation}
                                        checked={this.state.showContactInformation}
                                        data-testid={"ContactInformationId"} />
                                </SocialMediaHeaderBox>
                                {this.state.showContactInformation && (
                                    <ContactDetailsText data-test-id={"DisplayAreaTextId"}>
                                        {configJSON.textAreaWillDisplay}
                                    </ContactDetailsText>
                                )}
                            </FooterBox>
                            <FooterBox>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>Social Media
                                    </FooterContentHeadingText>

                                    <IOSSwitch
                                        name="is_social_media"
                                        checked={this.state.showSocialLinks}
                                        onChange={this.toggleSocialLinksBox}
                                        data-testid={"SocialMediaId"} />
                                </SocialMediaHeaderBox>
                                {
                                    this.state.showSocialLinks &&
                                    <BoxWithMarginLeft>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading data-test-id={"SocialMediaId"}>
                                                Facebook
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="facebook_url" type="text" value={this.state.facebookUrl} data-test-id={"facebookChangeTestId"} onChange={this.handleChangeFacebook} placeholder="www.facebook.com/builder.ai" />
                                                {this.state.checkStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isFacebookError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                Instagram
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="instagram_url" type="text" value={this.state.instagramUrl} data-test-id={"InstagramChangeTestId"} onChange={this.handleChangeInstagram} placeholder="www.instagram.com/builder.ai" />
                                                {this.state.checkInstagramStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorInstagramStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isInstagramError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>X</SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="x_url" type="text" value={this.state.twitterUrl} data-test-id={"TwitterChangeTestId"} onChange={this.handleChangeTwitter} placeholder="www.twitter.com/builder.ai" />
                                                {this.state.checkTwitterStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorTwitterStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isTwitterError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                Youtube
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="youtube_url" type="text" value={this.state.youTubeUrl} data-test-id={"YouTubeChangeTestId"} onChange={this.handleChangeYouTube} placeholder="www.youtube.com/builder.ai" />
                                                {this.state.checkYouTubeStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorYouTubeStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isYoutubeError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                    </BoxWithMarginLeft>
                                }
                            </FooterBox>
                            <FooterBox>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>App Links </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="is_app_link"
                                        checked={this.state.showStoreLinkInputBox}
                                        onChange={this.toggleAppStoreLinkInputBox}
                                        data-testid={"StoreId"} />
                                </SocialMediaHeaderBox>
                                {this.state.showStoreLinkInputBox && (
                                    <BoxWithMarginLeft>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading data-test-id={"DisplayStoreTextId"}>
                                                Play Store URL
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="playstore_url" type="text" value={this.state.playStoreUrl} onChange={this.handleChangePlayStore} data-test-id={"PlayStoreChangeTestId"} placeholder="www.play.google.com/store/apps/" />
                                                {this.state.checkPlayStoreStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.isPlayError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                            {this.state.errorPlayStoreStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                App Store URL
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="appstore_url" type="text" value={this.state.appStoreUrl} onChange={this.handleChangeAppStore} data-test-id={"AppStoreChangeTestId"} placeholder="www.apps.apple.com/in/app/micro..." />
                                                {this.state.checkAppStoreStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorAppStoreStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isAppError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                    </BoxWithMarginLeft>
                                )}
                            </FooterBox>
                            <CopyRightBox>
                                <FooterContentHeadingText>Copyright</FooterContentHeadingText>
                                <CopyrightInputBox name="copyright" type="text" value={this.props.footerCopyRight} onChange={this.props.handleChangeFooterCopyRight} data-test-id={"CopyRightChangeTestId"} />
                                <ErrorText>{this.props.footerCopyRightError}</ErrorText>
                            </CopyRightBox>
                        </FooterContentOuterBox>
                    )}

                </ContentBar>

                <Modal open={this.state.cropperSectionOpen}>
                    <CropperBox data-testid="cropper-dialog">
                        <TitleBlock style={{ padding: "35px 40px 0px" }}>
                            <ModalTitle>
                                {this.state.isBannerType === "mobile" ? configJSON.mobileBanner : configJSON.desktopBanner}
                                <Typography style={{ fontSize: "14px", color: "rgba(112, 112, 112, 1)", lineHeight: "32px" }}>{configJSON.mobileCropperText} {this.state.isBannerType === "mobile" ? configJSON.textMobileDevice : configJSON.textDesktopDevice}</Typography>
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                data-testid="closeButton"
                                onClick={this.handleSectionCropperClose}
                                style={{ width: "24px", height: "24px" }}
                            />
                        </TitleBlock>
                        <AddCropper
                            onCancel={this.handleSectionCropperClose}
                            onCancelProp={this.handleSelectCatalogue}
                            dataToPass={{
                                selectedFile: this.state.selectedFile,
                                type: this.state.selectedFileType,
                                ratio: 3.1
                            }}
                            isBanner={this.state.isBannerType}
                            onSubmit={(data: string) => this.handleCropperSubmit(data)}
                        />
                    </CropperBox>
                </Modal>

                <Modal open={this.state.uploadMobileBannerOpen}>
                    <SelectionBox>
                        <TitleBlock>
                            <ModalTitle data-test-id="BannerTestId">
                                {configJSON.textBannerImageSelection}
                                <ModalInnerText>{configJSON.selectionMobileText}</ModalInnerText>
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={this.handleSectionClose}
                                style={{ width: "24px", height: "24px" }}
                            />
                        </TitleBlock>
                        <TextSelectionPixel>
                            {this.state.isBannerType === "mobile" ? configJSON.textSelectionPixelMobile : configJSON.textSelectionPixel}

                        </TextSelectionPixel>
                        <GridTextCenter item xs={12}>
                            <UploadButton
                                color="secondary"
                                variant="contained"
                            >
                                <UploadBox
                                    imgRef={this.imgRef}
                                    data-testid={"UploadBanner"}
                                    onSelectFile={this.selectImageFile.bind(this)}
                                    uploadText={configJSON.textUploadButton}
                                />
                            </UploadButton>
                        </GridTextCenter>
                        <GridTextCenter item xs={12} style={webStyles.imagesBox}>
                            <Grid style={webStyles.uploadFileBox} onClick={() => { if (this.imgRef && this.imgRef.current) this.imgRef.current.click(); }}>
                                <p style={webStyles.textUplodFile}>{configJSON.uploadFileText}</p>
                                <p style={webStyles.textUploadFileContent}>{configJSON.uploadFileTextSummary}</p>
                            </Grid>
                            <Grid data-testid="builderLibBox" style={webStyles.uploadFileBox} onClick={() => { this.handleSelectCatalogue() }}>
                                <p style={webStyles.textUplodFile}>{configJSON.buiderLibraryText}</p>
                                <p style={webStyles.textUploadFileContent}>{configJSON.buiderLibraryTextSummary}</p>
                            </Grid>
                        </GridTextCenter>
                        {/* <TextChooseCatalogue onClick={this.handleSelectCatalogue}>{configJSON.textChooseCatalogue}</TextChooseCatalogue> */}
                    </SelectionBox>
                </Modal>

                <Modal open={this.state.uploadHeaderLogoModalOpen}>
                    <HeaderModalBox>
                        <TitleBlock style={{ padding: "40px 40px 0px" }}>
                            <ModalTitle>
                                {this.state.uploadModalType === "logo" ? configJSON.textUploadLogo : this.state.uploadModalType === "favicon" ? configJSON.textUploadFavicon : ""}
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={this.HandleLogoUploadModalClose}
                                style={{ width: "24px", height: "24px" }}
                            />
                        </TitleBlock>
                        <UploadBox data-test-id="LogoUploadModal" height="379px" width="617px" uploadMsg={configJSON.textUploadLogoModalText} onSelectFile={this.selectLogoFile.bind(this)} uploadIcon={plusIcon} sizeMsg={this.state.uploadModalType === "favicon" ? configJSON.textUploadFaviconModalMessage : configJSON.textUploadLogoModalMessage} acceptedFormat="image/png, image/jpeg, image/jpg" uploadIconStyles={{ height: '14px', width: '14px' }} />
                        <ModalContentFooter>
                            <CancelButton data-test-id="TriggerLogoModalClose" onClick={this.HandleLogoUploadModalClose}>Cancel</CancelButton>
                            <ModalEditImage data-test-id="TriggerLogoModalNext" style={{ background: "rgba(217, 217, 217, 1)" }} onClick={this.HandleNextForHeaderModal} disabled={!this.state.isHeaderImageUploaded}>Next</ModalEditImage>
                        </ModalContentFooter>
                    </HeaderModalBox>
                </Modal>


                <Modal open={this.state.HeaderCropSectionOpen}>
                    <HeaderModalBox style={{ height: this.state.isCustomFaviconSelected ? 599 : 500 }}>
                        <HeaderLogoTitleBox>
                            <ModalTitle>
                                {this.getLogoFaviconModalTitle()}
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={this.HandleLogoUploadModalClose}
                                style={{ width: 24, height: 24 }}
                            />
                        </HeaderLogoTitleBox>
                        {
                            this.state.uploadModalType === "logo" ? (
                                <AddCropper
                                    onCancel={this.HandleNextForHeaderModalClose}
                                    onRemove={this.openTextLogoModal}
                                    dataToPass={{
                                        selectedFile: this.state.selectedLogo,
                                        type: this.state.SelectedLogoFileType,
                                        isLogo: true,
                                    }}
                                    isBanner={false}
                                    onSubmit={(data: string) => this.handleLogoFaviconCropper(data)}
                                    data-test-id="CropModalSuccessSubmit"
                                    onRemoveFavicon={this.removeUploadedFavicon}
                                    withAspRatio={false}
                                />) : (
                                <>
                                    {
                                        this.state.isCustomFaviconSelected ?
                                            <AddCropper
                                                onCancel={this.HandleNextForHeaderModalClose}
                                                onRemove={this.openTextLogoModal}
                                                dataToPass={{
                                                    selectedFile: this.state.selectedFavicon,
                                                    type: this.state.SelectedFaviconFileType,
                                                    ratio: "1",
                                                    isLogo: false,
                                                }}
                                                isBanner={false}
                                                onSubmit={(data: string) => this.handleLogoFaviconCropper(data)}
                                                data-test-id="CropModalSuccessSubmit"
                                                onRemoveFavicon={this.removeUploadedFavicon}
                                            />
                                            : (
                                                <>
                                                    <Grid container justifyContent="center">
                                                        <Grid item xs={10} style={{ border: "2px solid #E6E6E6", height: "211px", width: "211px", maxWidth: "211px" }}>
                                                            <Typography
                                                                style={{
                                                                    fontSize: "128px",
                                                                    textAlign: "center",
                                                                    color: this.state.showProductPreview?.header?.logo_colour ?? "black",
                                                                    fontFamily: this.state.showProductPreview?.header?.logo_font ?? "Inter"
                                                                }}
                                                            >{this.props.storeName.substring(0, 1)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        flex: "0 0 1",
                                                        gap: "16px",
                                                        borderTop: "1px solid grey"
                                                    }}>
                                                        <Button variant="text" color="primary" style={{ paddingLeft: "37px", color: "#DC2626" }} onClick={this.HandleLogoUploadModalClose} >{configJSON.cancel}</Button>
                                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 48 }}>
                                                            <Button variant="text" color="primary" style={{ paddingLeft: "37px", color: "#6200EA" }} onClick={this.HandleNextForHeaderModalClose} >{configJSON.uploadNew}</Button>
                                                            <Button variant="contained" color="primary" style={{ padding: "24px 48px", minWidth: "160px" }} onClick={this.saveDefaultFavicon}>{configJSON.save}</Button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                    }
                                </>
                            )
                        }
                    </HeaderModalBox>
                </Modal>

                <Modal open={this.state.isTextLogoModal}>
                    <HeaderModalBox>
                        <TitleBlock style={{ padding: "40px 34px 0px" }}>
                            <ModalTitle>
                                Edit Logo
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={() => this.setState({ isTextLogoModal: false })}
                                style={{ width: "24px", height: "24px" }}
                            />
                        </TitleBlock>
                        <div style={{ display: "flex", padding: "0 35px", gap: "10px" }}>
                            <div>
                                <div style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "12px", lineHeight: "20px", color: "#000" }}>Choose Font</div>
                                <SectionSelectSubBox
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        keepMounted: true
                                    }}
                                    displayEmpty
                                    value={this.props.parentStates.textLogoMetaData.fontType}
                                    onChange={(event) => this.handleTextLogoFontChange(event.target.value as string)}
                                    style={{ marginBottom: 10 }}
                                >
                                    <DisabledMenuItem value="" disabled>Select Font</DisabledMenuItem>
                                    {configJSON.fontsList.map((font: string, index: number) => {
                                        return (
                                            <CustomMenuItem value={font} key={index}>{font}</CustomMenuItem>
                                        )
                                    })}
                                </SectionSelectSubBox>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "94%" }} >
                                <div style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "12px", lineHeight: "20px", color: "#000" }}>Choose Colour</div>

                                <ColorPicker
                                    convert={"hex"}
                                    variant="outlined"
                                    onChange={this.handleTextLogoColorChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <section
                                                    style={{
                                                        background: this.props.parentStates.textLogoMetaData.colour,
                                                        cursor: "pointer",
                                                        height: "30px",
                                                        width: "30px",
                                                        borderRadius: "50%"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            style: {
                                                color: "#475569",
                                                fontSize: "14px",
                                                textTransform: "uppercase",
                                                opacity: 1,
                                                fontWeight: 500,
                                                padding: "15.7px",
                                            }
                                        },
                                        value: this.props.parentStates.textLogoMetaData.colour
                                    }}
                                />
                                {this.state.errorLogoColor && (
                                    <ErrorText>
                                        Must be following the format #XXXXXX
                                    </ErrorText>
                                )}
                            </div>
                        </div>
                        <div style={{
                            border: "1px solid #E6E6E6",
                            margin: "0px 35px",
                            height: "211px",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            marginBottom: '80px'
                        }}>
                            <span style={{
                                fontFamily: `${this.props.parentStates.textLogoMetaData.fontType}`,
                                fontSize: "49.24px",
                                fontWeight: 500,
                                lineHeight: "56.28px",
                                letterSpacing: "-0.02em",
                                color: `${this.props.parentStates.textLogoMetaData.colour}`
                            }}>
                                {this.props.parentStates.storeName}
                            </span>
                        </div>
                        <ModalContentFooter style={{ justifyContent: 'space-between' }}>
                            <CancelButton data-test-id="TriggerLogoModalClose" style={{ paddingLeft: '34px' }} onClick={this.HandleLogoUploadModalClose}>Cancel</CancelButton>
                            <div style={{ display: 'flex' }}>
                                <Button variant="text" onClick={() => this.HandleNextForHeaderModalClose()} color="primary" style={{ marginRight: '24px', color: '#6200EA' }}>Upload from device</Button>
                                <ModalEditImage data-test-id="TriggerLogoModalNext" onClick={() => this.HandleTextLogoData()}>Save</ModalEditImage>
                            </div>
                        </ModalContentFooter>
                    </HeaderModalBox>
                </Modal >

                <CustomModal
                    open={this.state.selectedModal}
                    onClose={this.closeSelectedModal}
                >
                    <ModalContent>
                        <Box className="hiClass">
                            <Box style={{ padding: '20px 45px 10px 30px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <ModalHeaderBox>
                                    <ModalTitleText>Select image </ModalTitleText>
                                    <CloseModlaIcon src={CloseIcon} onClick={() => this.closeSelectedModal()} />
                                </ModalHeaderBox>
                                <Grid item xs={12} style={webStyles.imageBoxType} >
                                    <Grid data-testid="setImageType" onClick={() => { this.setImageTypeBox(configJSON.allText) }} style={this.state.selectImageType == configJSON.allText ? webStyles.selectedImageTypeBox : webStyles.imageTypeBox}>{configJSON.textAll}</Grid>
                                    <Grid onClick={() => { this.setImageTypeBox(configJSON.yourImagesText) }} style={this.state.selectImageType == configJSON.yourImagesText ? webStyles.selectedImageTypeBox : webStyles.imageTypeBox}>{configJSON.textYourImages}</Grid>
                                    <Grid onClick={() => { this.setImageTypeBox(configJSON.builderLibraryText) }} style={this.state.selectImageType == configJSON.builderLibraryText ? webStyles.selectedImageTypeBox : webStyles.imageTypeBox}>{configJSON.textBuilderLibrary}</Grid>
                                </Grid>
                                <ModalSearchImageBox>
                                    <SearchIconBox>
                                        <SearchIcon src={ModalSearchIcon} />
                                    </SearchIconBox>
                                    <SearchBoxInput placeholder="Search images" onChange={this.handleImageSearch} />
                                </ModalSearchImageBox>
                            </Box>
                            <Box style={webStyles.bodyBox}>
                                <MuiCustomImageCatalougeHolder container>
                                    {this.state.isLoading ?
                                        <Backdrop style={{ zIndex: 9999 }} open={this.state.isLoading}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                }}
                                            >
                                                <CircularProgress
                                                    size="5rem"
                                                    color="primary"
                                                    data-testid="page-loader"
                                                />
                                            </div>
                                        </Backdrop> :
                                        this.state.modalImages.length === 0 ? (
                                            <Grid item xs={12} style={webStyles.noResultBox}>
                                                <p style={webStyles.noResult}>
                                                    {configJSON.noResultText}
                                                </p>
                                            </Grid>
                                        ) : (
                                            this.state.modalImages.map((imageData, index: number) => {
                                                const { name, url, isSelect, type, category, sub_category } = imageData;
                                                return (
                                                    <Grid item xs={4} key={index}>
                                                        {
                                                            isSelect === true ?
                                                                <Box style={{ fontSize: '12px', lineHeight: '24px', fontWeight: 400 }}>
                                                                    <SelectedImage>
                                                                        <SelectedImageIconBox>
                                                                            <SelectedImageIcon src={checkIcon} />
                                                                        </SelectedImageIconBox>
                                                                        <LazyLoadImage src={url} alt={name} style={webStyle.bannerLibraryImg} />
                                                                    </SelectedImage>
                                                                    <span style={webStyles.fileName}>{name}</span>
                                                                    <p style={webStyles.categoryDiv}>
                                                                        <span style={webStyles.fileNameCategory}> {category && <> {configJSON.textCategory}{category}  </>}</span><br></br>
                                                                        <span style={webStyles.fileNameCategory}> {sub_category && <> {configJSON.textSubCategory}{sub_category} </>}</span>
                                                                    </p>
                                                                </Box>
                                                                :
                                                                <Box style={{ fontSize: '12px', lineHeight: '24px', fontWeight: 400 }}>
                                                                    <ImageBox onClick={() => this.selectImage(index, name)}>
                                                                        <LazyLoadImage src={url} alt={name} style={webStyle.bannerLibraryImg} />
                                                                    </ImageBox>
                                                                    <span style={webStyles.fileName}>{name}</span>
                                                                    <p style={webStyles.categoryDiv}>
                                                                        <span style={webStyles.fileNameCategory}>{category && <> {configJSON.textCategory}{category} </>}</span><br></br>
                                                                        <span style={webStyles.fileNameCategory}>{sub_category && <> {configJSON.textSubCategory}{sub_category} </>}</span>
                                                                    </p>
                                                                </Box>
                                                        }
                                                    </Grid>
                                                );
                                            })
                                        )
                                    }
                                </MuiCustomImageCatalougeHolder>
                            </Box>
                        </Box>
                        <ModalContentFooter>
                            <CancelButton onClick={this.closeSelectedModal}>Cancel</CancelButton>
                            <ModalEditImage onClick={this.handleNextCatalogue} disabled={!this.state.selectedImage.isSelect} style={{ height: '64px', width: '128px', background: this.state.selectedImage.isSelect ? "rgba(0, 214, 89, 1)" : "rgba(217, 217, 217, 1)", color: this.state.selectedImage.isSelect ? "rgba(60, 62, 73, 1)" : "rgba(255, 255, 255, 1)" }}>Next</ModalEditImage>
                        </ModalContentFooter>
                    </ModalContent>
                </CustomModal>

                <DeleteCustomModal
                    open={this.state.deleteSectionModal}
                    onClose={this.closeDeleteSectionModal}
                >
                    <DeleteModalContentBox>
                        <ModalHeadingBox>
                            <ModalHeadingFlexBox>
                                <DeleteModalTitle>Are you sure?</DeleteModalTitle>
                                <CloseModalButton onClick={this.closeDeleteSectionModal}>
                                    <DeleteCloseIcon src={CloseIcon} />
                                </CloseModalButton>
                            </ModalHeadingFlexBox>
                            <ModalInformationText>{configJSON.textModalRemoved}</ModalInformationText>
                        </ModalHeadingBox>
                        <ModalFooterBox>
                            <CancelDeleteButton onClick={this.closeDeleteSectionModal}>Cancel</CancelDeleteButton>
                            <YesDeleteButton onClick={this.deleteSection}>Yes, delete</YesDeleteButton>
                        </ModalFooterBox>
                    </DeleteModalContentBox>
                </DeleteCustomModal>

                <Drawer
                    anchor="bottom"
                    open={this.state.isAddTextToBannerDrawerOpen}
                    onClose={() => this.toggleAddTextToBannerDrawer(false, this.state.chosenBannerPlatform)}
                    hideBackdrop
                    PaperProps={{
                        style: {
                            left: 'auto',
                            right: '20px',
                            width: "400px",
                            height: "420px",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            boxShadow: "-4px -9px 20px 0px #00000033",
                            borderTop: '9px solid #6200EA',
                        }
                    }}
                    style={{ position: 'static' }}
                >
                    <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "30px 24px 0 24px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: '20px' }}>
                            {this.state.chosenBannerPlatform === "mobile" &&
                                <div style={{ width: "100%" }}>
                                    <SectionDropDownHeading>Text Alignment</SectionDropDownHeading>
                                    <SectionSelectSubBox
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null,
                                            keepMounted: true
                                        }}
                                        displayEmpty
                                        value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].textAlignment}
                                        style={{ width: "100%" }}
                                    >
                                        <DisabledMenuItem value="" disabled>Select Alignment</DisabledMenuItem>
                                        {configJSON.textAlignmentList.map((font: string, index: number) => {
                                            return (
                                                <CustomMenuItem
                                                    onClick={() => this.onChangeBannerTextProperty("textAlignment", font, this.state.chosenBannerPlatform)}
                                                    value={font}
                                                    key={index}
                                                    defaultValue={"center"}
                                                >{font}</CustomMenuItem>
                                            )
                                        })}
                                    </SectionSelectSubBox>
                                </div>
                            }
                            <div style={{ width: "100%" }}>
                                <SectionDropDownHeading>{configJSON.fontName}</SectionDropDownHeading>
                                <SectionSelectSubBox
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        keepMounted: true
                                    }}
                                    displayEmpty
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontFamily}
                                    style={{ width: "100%" }}
                                >
                                    <DisabledMenuItem value="" disabled>{configJSON.selectFont}</DisabledMenuItem>
                                    {configJSON.fontsList.map((font: string, index: number) => {
                                        return (
                                            <CustomMenuItem
                                                onClick={() => this.onChangeBannerTextProperty("fontFamily", font, this.state.chosenBannerPlatform)}
                                                value={font}
                                                key={index}
                                            >{font}</CustomMenuItem>
                                        )
                                    })}
                                </SectionSelectSubBox>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <SectionDropDownHeading>Header Text</SectionDropDownHeading>
                            <TextField
                                multiline
                                minRows={3}
                                maxRows={3}
                                placeholder="Enter your text here"
                                style={{ width: "100%" }}
                                variant="outlined"
                                InputProps={{ style: { padding: 12 } }}
                                value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].headerText}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeBannerTextProperty("headerText", e.target.value, this.state.chosenBannerPlatform)}
                            />
                            <span style={{ position: 'absolute', bottom: "10px", right: "10px", color: "#64748B", fontSize: "10px" }}>{this.state.bannerTextProperties[this.state.chosenBannerPlatform].headerText?.length}/40</span>

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: '20px' }}>
                            <div style={{ width: "100%" }} className="color-picker-on-drawer-marketing" >
                                <SectionDropDownHeading>Font Colour</SectionDropDownHeading>
                                <ColorPicker
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontColor}
                                    hintText={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontColor}
                                    onChange={(color: string) => this.onChangeBannerTextProperty("fontColor", color, this.state.chosenBannerPlatform)}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <section
                                                    style={{
                                                        background: this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontColor,
                                                        cursor: "pointer",
                                                        height: "24px",
                                                        width: "24px",
                                                        borderRadius: "50%"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            style: {
                                                color: '#475569',
                                                fontSize: "14px",
                                                textTransform: 'uppercase',
                                                opacity: 1,
                                                fontWeight: 500,
                                                padding: '15.7px 10px',
                                            }
                                        },
                                    }}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <SectionDropDownHeading>Font Size</SectionDropDownHeading>
                                <SectionInputSubBox
                                    type="number"
                                    placeholder="Enter font size"
                                    style={{ width: "100%" }}
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontSize}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        this.onChangeBannerTextProperty("fontSize", e.target.value, this.state.chosenBannerPlatform)}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: "1px solid #E8E8E8", marginTop: 'auto', display: "flex", justifyContent: "flex-end", gap: 20, height: "64px", alignItems: "center" }}>
                        <button
                            onClick={() => this.revertChangesForBannerText()}
                            style={{ backgroundColor: '#fff', color: '#3C3E49', fontSize: "12px", fontWeight: 400, textAlign: 'center', cursor: 'pointer', height: '100%', borderRadius: "3px", border: 'none', textDecoration: 'underline' }}
                        >Cancel</button>
                        <button
                            onClick={() => this.state.chosenBannerPlatform === "mobile" ? this.handleMobileBannerMetadata() : this.handleDesktopBannerMetadata()}
                            style={{ backgroundColor: '#00D659', color: '#3C3E49', fontSize: "16px", fontWeight: 500, textAlign: 'center', cursor: 'pointer', width: 132, height: '100%', borderRadius: "3px", border: 'none' }}
                        >Apply</button>
                    </div>
                </Drawer>

                <CustomHeaderModal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open={this.state.isHeaderOpen}
                >
                    <Paper className="paper">
                        <Box className="firstContainer">
                            <Box className="mainSection">
                                <Box className="modalHeadbox">
                                    <Typography className="modalHeading">
                                        {configJSON.chooseHeaderText}
                                    </Typography>
                                    <Close
                                        src={CloseIcon}
                                        onClick={this.handleSectionClose}
                                        style={{ width: "24px", height: "24px" }}
                                    />
                                </Box>

                            </Box>
                            <Box className="cardContainer">
                                {this.state.headerTemplate.map((item: HeaderTemObj, index: number) => {
                                    return (
                                        <>
                                            <Box key={index}
                                                className={item.attributes.name === this.state.selectHeader ? "selected cardSection" : "cardSection"} onClick={() => this.handleHeaderSelect(item.attributes.name, item.id)}>
                                                <Box className="cardBox">
                                                    <Box className="imageBox">
                                                        <img src={bannerSelection[index]?.bannerImg} width={"100%"} />
                                                    </Box>
                                                    <Typography className="imageName">{item.attributes.name}</Typography>
                                                </Box>
                                            </Box>
                                        </>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box className="btnBox">
                            <Button className="cancelBtn" onClick={this.handleHeaderCancle}>{configJSON.cancel}</Button>
                            <Button className="applyBtn" onClick={this.handleHeaderThemeApply}>{configJSON.apply}</Button>
                        </Box>
                    </Paper>
                </CustomHeaderModal>
                {RendertemplateModal(this.state.activeNumber, this.state.selectedSection)}


            </>
            // Customizable Area End
        );

    }
};

// Customizable Area Start
export default withDialog(withLoader(withHeaderBar(ContactInformation)));
export { ContactInformation }
// Customizable Area End

// Customizable Area Start

const webStyle = {
    addSection: {
        padding: "15px"
    },
    colorPattel: {
        width: "320px"
    },
    bannerLibraryImg: {
        height: "100%",
        width: "100%",
        objectFit: "contain" as "contain"
    }
}

const CustomHeaderModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .sectionPaper": {
        borderRadius: "0px",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        width: "1300px"
    },
    "& .paper": {
        borderRadius: "0px",
        display: "flex",
        flexDirection: "column",
         overflowY: "scroll",
        width: "1000px"
    },
    "& .firstContainer": {
        padding: "30px 35px 5px"
    },
    "& .btnBox": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        gap: "30px"
    },
    "& .applyBtn": {
        backgroundColor: "#00D659",
        borderRadius: "0px",
        height: "60px",
        minWidth: "185px",
        cursor: "pointer",
        "& .MuiButton-label": {
            color: "#3C3E49",
            textTransform: "none",
            fontWeight: 500,
            fontFamily: "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            width: "130px",
            cursor: "pointer"
        },
    },
    "& .cancelBtn": {
        "& .MuiButton-label": {
            color: "#DC2626",
            textTransform: "none",
            textDecoration: "underline",
            fontWeight: 400,
            fontFamily: "Rubik",
            fontSize: "12px",
            lineHeight: "20px",
        },
    },
    "& .mainSection": {
        padding: "30px 30px 10px 30px",
        boxSizing: "border-box",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
    },
    "& .modalHeadbox": {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
            color: "#3C3E49",
            fontWeight: 500,
            fontFamily: "Rubik",
            fontSize: "28px",
            lineHeight: "32px",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "34px",
            cursor: "pointer",
        },
    },
    "& .cardContainer": {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "30px",
        marginTop: "15px",
        boxSizing: "border-box",
    },
    "& .cardSection": {
        width: "48%",
        "&:hover": {
            border: "1px solid #6200EA"
        }
    },
    "& .selected": {
        border: "1px solid #6200EA"
    },
    "& .cardBox": {
        padding: "10px",
        cursor: "pointer",
    },
    "& .imageBox": {
        marginBottom: "10px"
    },
    "&. imageName": {
        fontSize: "16px",
        fontWight: 600,
        fontFamily: "Rubik",
        lineHeight: "18.96px",
        color: "#000000"
    },
    "& .catalogueBox": {
        padding: "15px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
    },
    "& .catalogueHeader": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 30px",
    },
    "& .logoBox": {
        display: "flex",
        gap: "20px",
        alignItems: "center",
        "& .MuiTypography-root": {
            color: "#3C3E49",
            fontWeight: 500,
            fontFamily: "Rubik"
        },
    },
    "& .menuBox": {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        "& .MuiTypography-root": {
            color: "#3C3E49",
            fontWeight: 400,
            fontFamily: "Rubik",
        },
    },
    "& .catalogueMainSection": {
        display: "flex",
        height: "200px",
        background: "#3C3E49",
        padding: "30px",
        "& .justifyImage": {
            justifyContent: "flex-end",
        },
    },
    "& .mainSectionBox": {
        flex: 1,
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
            color: "#ffffff",
            fontWeight: 500,
            fontFamily: "Rubik",
            fontSize: "28px",
            lineHeight: "32px",
        },
        "& img": {
            borderTopLeftRadius: "10px",
            borderBottomRightRadius: "10px",
        },
    },
    "& .catalogueName": {
        padding: "20px 0px",
        color: "black",
        fontWeight: 500,
        fontFamily: "Rubik",
        fontSize: "16px",
        lineHeight: "18.96px",
    },
    "& .centerJustified": {
        position: "relative",
        padding: "5px 0px",
        "& .manageButton": { position: "absolute", right: 30 },
        display: "flex",
        justifyContent: "center"
    },
    "& .blackBack": {
        background: "#3C3E49",
        "& .logoBox": {
            "& .MuiTypography-root": {
                color: "#ffffff !important"
            }
        },
        "& .menuBox": {
            "& .MuiTypography-root": {
                color: "#ffffff !important"
            }
        }
    },
    "& .manageButton": {
        height: "30px",
        padding: "0px 10px",
        background: "pink",
        "& .MuiButton-label": {
            color: "white",
            textTransform: "none",
            fontWeight: 400,
            fontFamily: "Rubik",
            fontSize: "12px",
            lineHeight: "20px",
        }
    },
    "& .dotsComingSoon": {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#CBD5E1",
        margin: "0 5px",
    },
    "& .carousel": {
        fontSize: "16px",
        color: "#000000",
        fontWeight: 500,
        lineHeight: "19px",
         marginTop: "20px"
    },
    "& .subTitle": {
        fontSize: "14px",
        color: "rgba(0,0,0,0.75)",
        fontWeight: 400,
        lineHeight: "21px",
         marginTop: "6px"
    },
    "& .comingsoonMainContainer": {
        // flex:1,
        padding: "10px",
        height: "100%",
        boxShadow: "0px 7px 10px 0px #0000000D",
        position: "relative",
        margin: "15px 0"
    },
    "& .selectedCatalogue": {
        border: "1px solid #6200EA",
        height:'100%'
    },
    "& .comingsoonInnerContainer": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        bottom: "32%",
        left: "31%",
        color: "white"
    },
    "& .comingSoon": {
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "13px",
        paddingTop: "10px",
        paddingBottom: "18px"
    },
    "& .popUpOptions": {
        fontSize: "20px",
        color: "#1E293B",
        fontWeight: 400,
        lineHeight: "58px",
        width: "250px",
        paddingLeft: "20px"
    },
    "& .isActive": {
        backgroundColor: "#F0E5FF"
    },
    "& .outerContainer": {
        display: "flex",
        flexDirection: "column",
        height: 'fit-content'
    },
    "& .headingMainContainer": {
        display: "flex",
        gap: "12px",
        flexDirection: "column",
        borderBottom: "1px solid #CBD5E1",
        padding: "40px"
    },
    "& .addSectionCls": {
        fontSize: "28px",
        color: "#3C3E49",
        fontWeight: 500,
        lineHeight: "32px"
    },
    "& .subTitleHading": {
        fontSize: "12px",
        color: "#9B9FB1",
        fontWeight: 400,
        lineHeight: "20px"
    }
});



const CustomMenuItem = styled(MenuItem)({
    "&.MuiListItem-root.Mui-selected": {
        backgroundColor: "#fff",
    },
    "&.MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#00000014",
    },
    "&.MuiSelect-select": {
        backgroundColor: "#fff !important",
    },
    "&.Mui-focused .MuiSelect-select": {
        backgroundColor: "#fff !important",
    },
});

const MuiCustomImageCatalougeHolder = styled(Grid)({
    position: 'relative',
    maxHeight: '350px',
    overflowY: 'auto',
    scrollBehavior: "smooth",
    '&::-webkit-scrollbar': {
        width: '4px',
        height: '91px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 214, 89, 1)',
        borderRadius: '4px',
        marginRight: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(0, 214, 89, 0.8)',
    },
    '&::-webkit-scrollbar-track': {
        borderRadius: '4px',
    },
});

const SectionSelectIcon = styled("img")({
    position: "absolute",
    top: "15px",
    left: "10px"
});

const SectionDropDownText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
});

const SectionDropDownIcon = styled("img")({
    height: "10.5px",
    width: "10.5px",
    objectFit: "contain"
});

const AddSectionButtonBox = styled(Box)({
    padding: "15px 45px",
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
    cursor: "pointer"
});

const AddSectionButtonBoxex = styled(Box)({
    padding: "15px 45px",
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
    cursor: "pointer"
});

const AddSectionButtonIcon = styled("img")({
    height: "15px",
    width: "15px",
    objectFit: "contain"
});

const AddSectionButtonText = styled(Typography)({
    color: "#6200EA",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline"
});

const SectionDropDownHeading = styled(Typography)({
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "0px 0px",
    marginBottom: "4px"
});

const SectionDropDownSubHeading = styled(Typography)({
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "10px 0px",
    marginBottom: "4px"
});

const SectionSelectSubBox = styled(Select)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
    background: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:focus": {
        outline: "none",
    },
    "&&::before": {
        content: "none",
    },
    "&&::after": {
        content: "none",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "white !important",
    },
});

const SectionAutoCompleteSubBox = styled(Autocomplete)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    background: "#fff",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:focus": {
        outline: "none",
    },
    "&&::before": {
        content: "none",
    },
    "&&::after": {
        content: "none",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "white !important",
    },
    "& .MuiOutlinedInput-root": {
        width: "320px",
        height: "48px",
    }
});

const SectionInputSubBox = styled("input")({
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
    backgroundColor: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    color: "#3C3E49",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    outline: "none"
});

const SectionInputNameBox = styled("input")({
    border: "none !important",
    outline: "none !important",
    width: "100%",
});

const DisabledMenuItem = styled(MenuItem)({
    display: "none"
})

const SectionContentDropDownBox = styled(Box)({
    width: "96%",
    borderBottom: "1px solid #E2E2E2",
    boxSizing: "border-box",
    cursor: "pointer",
    position: "relative",
    marginLeft: '0px'
});

const SectionDropDownBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    paddingLeft: "30px",
    boxSizing: "border-box"
});

const DeleteBox = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "15px"
});

const DeleteButtonSectionBox = styled(Box)({
    // width: "320px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
});

const BannerPreview = styled(Box)({
    margin: "25px 0",
    position: "relative",
});

const EditImageIcon = styled(Box)({
    position: "absolute",
    right: "25px",
    top: "8px",
    cursor: "pointer"
});

const DeleteSectionButton = styled("button")({
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    border: "none"
});

const DeleteButtonIcon = styled("img")({
    display: "flex",
    width: "16px !important",
    height: "16px !important",
    justifyContent: "center",
    alignItems: "center"
});

const SectionContentDropDown = styled(Box)({
    width: "100%",
    padding: "0px",
    paddingLeft: "50px",
    marginBottom: "10px"
});

const RedStar = styled(Box)({
    color: "red",
    fontSize: "14px",
    fontWeight: 500,
});

const InputFieldText = styled(Typography)({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#3C3E49",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    overflow: "hidden"
});

const UploadIcon = styled("img")({
    height: "24px",
    width: "24px",
    transition: "all 1s ease-in-out",
});

const UploadButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
    background: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
});

const InputTextFieldBox = styled(TextField)({
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    background: "#fff",
    paddingRight: "20px",
    marginBottom: "15px"
});

const CropperBox = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "3px",
    width: "684px",
    height: "599px",
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& .MuiGrid-container": {
        "& .cropperClass": {
            maxWidth: "100%",
            "& .cropper-main-class": {
                "& .cropper-container": {
                    width: "100% !important"
                }
            }
        },

    },
});

const SelectionBox = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "3px",
    width: "684px",
    height: "352px",
    flexShrink: 0
})

const HeaderModalBox = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "3px",
    width: "684px",
    height: "599px",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .extraClass": {
        margin: 'auto',
        "& .ExtraClassDropContainer": {
            "& .ExtraClassdropMessage": {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '15px',
                "& .ExtraClassdropMessage": {
                    fontSize: '20px !important',
                    fontWeight: "500 !important",
                    color: 'rgba(103, 107, 126, 1) !important',
                    lineHeight: '24px !important',
                    fontFamily: "Rubik !important"
                },
                "& .ExtraClasssizeMsg": {
                    fontSize: '12px !important',
                    color: 'rgba(103, 107, 126, 1) !important',
                    padding: '0 85px',
                    lineHeight: '24px !important',

                },
            }
        }
    },
})

const Close = styled("img")({
    "&:hover": {
        cursor: "pointer",
    },
});

const TitleBlock = styled("div")({
    display: "flex",
    padding: "40px",
    color: "#3C3E49",
    width: "100%",
    justifyContent: "space-between"
});

const HeaderLogoTitleBox = styled("div")({
    display: "flex",
    padding: '40px 55px',
    color: "#3C3E49",
    width: "100%",
    justifyContent: "space-between"
});

const ModalTitle = styled(Typography)({
    color: "#3C3E49",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    fontStretch: "normal",
    fontFamily: "Rubik"
});

const ModalInnerText = styled(Typography)({
    fontFamily: "Rubik",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "48px",
    color: "#9B9FB1"
});

const TextSelectionPixel = styled(Typography)({
    color: "#676B7E",
    textAlign: "center",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "10px 125px",
});

const UploadButton = styled(Button)({
    display: 'none',
    padding: "0",
    background: "#00D659",
    borderRadius: "3px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C3E49",
    textTransform: "none",
    width: "259px",
    height: "48px",
    alignItems: "center",
    "& div": {
        width: "100%",
        margin: "0px"
    },
    "& div div": {
        border: "none",
        display: "flex",
        justifyContent: "center",
        padding: "0"
    },
    "& .Component-dropMessage-130": {
        margin: "0"
    }
});

const GridTextCenter = styled(Grid)({
    textAlign: "center"
});

const CustomModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

// const CustomHeaderModal = styled(Modal)({
//     height: "100%",
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     "& .paper": {
//         borderRadius: "0px",
//         display: "flex",
//         flexDirection: "column",
//         overflowY: "scroll",
//         width: "1000px"
//       },
//       "& .firstContainer":{
//         padding: "30px 35px 5px"
//       },
//       "& .btnBox": {
//         width: "100%",
//         display: "flex",
//         justifyContent: "flex-end",
//         gap: "5px"
//       },
//       "& .applyBtn": {
//         backgroundColor: "#00D659",
//         borderRadius: "0px",
//         height: "60px",
//         minWidth: "185px",
//         cursor: "pointer",
//         "& .MuiButton-label": {
//           color: "#3C3E49",
//           textTransform: "none",
//           fontWeight: 500,
//           fontFamily: "Rubik",
//           fontSize: "16px",
//           lineHeight: "24px",
//         },
//       },
//       "& .cancelBtn": {
//         "& .MuiButton-label": {
//           color: "#DC2626",
//           textTransform: "none",
//           textDecoration: "underline",
//           fontWeight: 400,
//           fontFamily: "Rubik",
//           fontSize: "12px",
//           lineHeight: "20px",
//           width: "130px",
//           cursor: "pointer"
//         },
//       },
//       "& .mainSection": {
//         boxSizing: "border-box",
//         display: "flex",
//         gap: "20px",
//         flexDirection: "column",
//       },
//       "& .modalHeadbox": {
//         width: "100%",
//         justifyContent: "space-between",
//         display: "flex",
//         alignItems: "center",
//         "& .MuiTypography-root": {
//           color: "#3C3E49",
//           fontWeight: 500,
//           fontFamily: "Rubik",
//           fontSize: "28px",
//           lineHeight: "32px",
//         },
//         "& .MuiSvgIcon-root": {
//           fontSize: "34px",
//           cursor: "pointer",
//         },
//       },
//       "& .cardContainer":{
//         width: "100%",
//         display: "flex",
//         flexWrap: "wrap",
//         gap: "30px",
//         marginTop: "15px",
//         boxSizing: "border-box",
//       },
//       "& .cardSection":{
//         width: "48%",
//         "&:hover":{
//             border: "1px solid #6200EA"
//         }
//       },
//       "& .selected":{
//         border: "1px solid #6200EA"
//       },
//       "& .cardBox":{
//         padding: "10px",
//         cursor: "pointer",
//       },
//       "& .imageBox":{
//         marginBottom: "10px"
//       },
//       "&. imageName":{
//         fontSize: "16px",
//         fontWight: 600,
//         fontFamily: "Rubik",
//         lineHeight: "18.96px",
//         color: "#000000"
//       },
//       "& .catalogueBox": {
//         padding: "15px",
//         boxSizing: "border-box",
//         display: "flex",
//         flexDirection: "column",
//       },
//       "& .catalogueHeader": {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         padding: "0px 30px",
//       },
//       "& .logoBox": {
//         display: "flex",
//         gap: "20px",
//         alignItems: "center",
//         "& .MuiTypography-root": {
//           color: "#3C3E49",
//           fontWeight: 500,
//           fontFamily: "Rubik"
//         },
//       },
//       "& .menuBox": {
//         display: "flex",
//         gap: "10px",
//         alignItems: "center",
//         "& .MuiTypography-root": {
//           color: "#3C3E49",
//           fontWeight: 400,
//           fontFamily: "Rubik",
//         },
//       },
//       "& .catalogueMainSection": {
//         display: "flex",
//         height: "200px",
//         background: "#3C3E49",
//         padding: "30px",
//         "& .justifyImage": {
//           justifyContent: "flex-end",
//         },
//       },
//       "& .mainSectionBox": {
//         flex: 1,
//         display: "flex",
//         alignItems: "center",
//         "& .MuiTypography-root": {
//           color: "#ffffff",
//           fontWeight: 500,
//           fontFamily: "Rubik",
//           fontSize: "28px",
//           lineHeight: "32px",
//         },
//         "& img": {
//           borderTopLeftRadius: "10px",
//           borderBottomRightRadius: "10px",
//         },
//       },
//       "& .catalogueName": {
//         padding: "20px 0px",
//         color: "black",
//         fontWeight: 500,
//         fontFamily: "Rubik",
//         fontSize: "16px",
//         lineHeight: "18.96px",   
//       },
//       "& .centerJustified": {
//         position: "relative",
//           padding: "5px 0px",
//         "& .manageButton":{position: "absolute", right: 30},
//         display: "flex",
//         justifyContent: "center"
//       },
//       "& .blackBack": {
//         background: "#3C3E49",
//         "& .logoBox": {
//             "& .MuiTypography-root": {
//                 color: "#ffffff !important"
//               }
//         },
//         "& .menuBox": {
//             "& .MuiTypography-root": {
//                 color: "#ffffff !important"
//               }
//         }
//       },
//       "& .manageButton":{
//         height: "30px",
//         padding: "0px 10px",
//         background: "pink",
//         "& .MuiButton-label": {
//             color: "white",
//             textTransform: "none",
//             fontWeight: 400,
//             fontFamily: "Rubik",
//             fontSize: "12px",
//             lineHeight: "20px",
//           }
//       }
// });

const ModalContent = styled(Box)({
    width: "684px",
    minHeight: "541px",
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
});

const ModalSearchImageBox = styled(Box)({
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #D0D2DA",
    backgroundColor: "#FFF",
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    padding: "11.5px 0px",
    gap: "5px"
});

const SearchIconBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const SearchIcon = styled("img")({
    width: "16px",
    height: "16px",
    marginLeft: "10px"
});

const SearchBoxInput = styled("input")({
    width: "100%",
    height: "100%",
    border: "none",
    outline: "none",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    maxHeight: "16px",
    "&::placeholder": {
        color: "#AFB2C0",
    }
});

const ModalHeaderBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const ModalBannerInnerText = styled(Typography)({
    color: "#707070",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.28px"
});

const ModalTitleText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
});

const CloseModlaIcon = styled("img")({
    width: "22.5px",
    height: "22.5px",
    cursor: "pointer"
});

const ModlaContentBox = styled(Box)({
    margin: "11px 30px",
    display: "flex",
    flexWrap: "wrap",
    height: "412px",
    overflowY: "scroll",
    gap: "8px",
    "&::-webkit-scrollbar": {
        width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#00D659",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#F8F9FA",
    },
});

const ModalContentFooter = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid #E8E8E8",
});

const ModalEditImage = styled("button")({
    width: "120px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 214, 89, 1)",
    border: "none",
    minHeight: "100%",
    cursor: "pointer",
    color: "rgba(60, 62, 73, 1)",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    boxSizing: "border-box",
    transition: "background-color 0.3s ease",
});


const ImageBox = styled(Box)({
    width: "196px",
    height: "110px",
    cursor: "pointer"
});

const SelectedImage = styled(Box)({
    width: "196px",
    height: "110px",
    border: "3px solid #00D659",
    position: "relative",
    boxSizing: "border-box"
});

const SelectedImageIconBox = styled(Box)({
    width: "24px",
    height: "24px",
    borderRadius: "3px",
    background: "#00D659",
    position: "absolute",
    top: "45px",
    left: "88px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const SelectedImageIcon = styled("img")({
    height: "24px",
    width: "24px"
});

const CatalogueImage = styled("img")({
    height: "100%",
    width: "100%",
    objectFit: "contain"
});

const CancelButton = styled(Box)({
    padding: "20px 48px",
    borderRadius: "3px",
    border: "none",
    height: "100%",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline",
    color: "#6200EA"
});

//======Footer css========//
const ContentBar = styled(Box)({
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    overflowY: "scroll",
    height: "calc(100vh - 242px)",
    minWidth: "445px",
    width: "445px",
})

const ContentButtonBar = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 10px 20px",
    backgroundColor: "#f6f7f9",
    width: "390px",
    height: "43px",
    margin: "10px 0",
    boxSizing: "border-box",
    cursor: "pointer"
})

const ContentHeadinText = styled(Typography)({
    color: "#475569",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    fontFamily: "'Rubik', sans-serif !important",
})

const FooterContentOuterBox = styled(Box)({
    padding: "0 15px 0 0",
    margin: '0 20px'
});

const SocialInputBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginBottom: "0px"
});

const FooterContentHeadingText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "10px 0",
});

const FooterBox = styled(Box)({
    borderBottom: "1px solid #e2e2e2",
    marginLeft: '20px'
});

const HeaderBoxContent = styled(Box)({
    borderBottom: "1px solid #e2e2e2",
    padding: "0 0 20px 30px",
});

const CustomizeHeader = styled(Box)({
    borderBottom: "1px solid #e2e2e2",
    padding: "26px 0 20px 30px",
    "& .customHeaderText": {
        color: "#3C3E49",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
    },
    "& .customBodyText": {
        color: "#5E5E5E",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
        margin: "8px 0px 20px 0px"
    },
    "& .imageBox": {
        maxWidth: "384px",
        position: "relative"
    },
    "& .editBtn": {
        position: "absolute",
        right: "10px",
        top: "7px",
        cursor: "pointer"
    },
    "& .bannerName": {
        color: "#000000",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18.96px",
        marginTop: "18.91px"
    },
});

const SocialMediaLinksBox = styled(Box)({
    margin: "0px 0",
});

const ImageInfo = styled("img")({
    marginLeft: "10px"
});

const ValidUrl = styled("img")({
    position: "absolute",
    right: "3%",
    background: "#FFF"
});

const SocialMediaLinksHeading = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: "10px !important"
});

const SocialMediaLinksInputBox = styled("input")({
    width: "100%",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid  #D0D2DA",
    backgroundColor: "#FFF",
    margin: "3px 0",
    color: "#1E293B",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "10px",
    boxSizing: "border-box",
    outline: "none",
    "&::placeholder": {
        color: "#94A3B8",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
    }
});

const SocialMediaHeaderBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    margin: "10px 0px",
    justifyContent: "space-between",
    "& .MuiSwitch-track": {
        backgroundColor: "#94A3B8 !important"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#00D659 !important"
    }
});

const CopyrightInputBox = styled("input")({
    width: "100%",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid  #D0D2DA",
    backgroundColor: "#FFF",
    margin: "10px 0",
    color: "#000",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "10px",
    boxSizing: "border-box",
    outline: "none",
});

const CopyRightBox = styled(Box)({
    padding: "20px",
});

const BoxWithMarginLeft = styled(Box)({
    padding: "0px 0",
    paddingLeft: "20px",
    position: "relative"
});

const ContactDetailsText = styled(Box)({
    color: "#1E293B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "10px 0px",
    width: "346px"
});

const ErrorText = styled(Typography)({
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px"
});

const DeleteCustomModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
});

const DeleteModalContentBox = styled(Box)({
    width: "684px",
    height: "277px",
    borderRadius: "3px",
    backgroundColor: "#fff",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
});

const ModalFooterBox = styled(Box)({
    borderTop: "1px solid #E8E8E8",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
});

const ModalHeadingBox = styled(Box)({
    padding: "40px",
    boxSizing: "border-box"
});

const YesDeleteButton = styled("button")({
    padding: "20px 48px",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: "#FF1744",
    color: "#FFF",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    border: "none",
    cursor: "pointer"
});
const CancelDeleteButton = styled("button")({
    padding: "20px 48px",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: "#fff",
    color: "#3C3E49",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
});

const ModalHeadingFlexBox = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
});

const DeleteModalTitle = styled(Typography)({
    color: "#3C3E49",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
});

const CloseModalButton = styled("button")({
    display: "flex",
    width: "24px",
    height: "24px",
    padding: "0.749px 0.75px 0.751px 0.75px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none"
})
const DeleteCloseIcon = styled("img")({
    width: "22.5px",
    height: "22.5px",
})
const ModalInformationText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
})

const HeaderDropDownSubHeading = styled(Typography)({
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0px",
    color: 'rgba(71, 85, 105, 1) !important',
    display: 'flex',
    alignItems: "center",
    gap: '10px',
    paddingLeft: '20px'
});

const TestimonialContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px"
});

const TestimonialTextBox = styled(Box)({
    gap: "0px",
    display: "flex",
    alignItems: "center"
});

const TestimonialText = styled(Typography)({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: "4px",
    marginRight: "5px",
    color: "#3C3E49",
    gap: "0"
});
const webStyles = {
    imageBoxType: {
        display: "flex",
    },
    selectedImageTypeBox: {
        backgroundColor: "#F0E5FF",
        borderRadius: "16px",
        border: "1px solid #6200EA",
        gap: "7px",
        padding: "5px 12px",
        marginRight: "5px",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#6200EA",
        cursor: "pointer"
    },
    imageTypeBox: {
        backgroundColor: "#F1F5F9",
        borderRadius: "16px",
        gap: "7px",
        padding: "5px 12px",
        marginRight: "5px",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#334155",
        cursor: "pointer"
    },
    bodyBox: {
        padding: '10px 35px 10px 30px'
    },
    noResultBox: {
        border: "2px solid #ECEDF0",
        padding: "20px 85px",
        textAlign: "center" as const,
        marginRight: "10px"
    },
    noResult: {
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "26px",
    },
    noResultText: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#83889E",
    },
    fileName: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "15px",
        color: "#000",
        padding: "5px 0px"
    },
    categoryDiv: {
        marginBottom: "10px"
    },
    fileNameCategory: {
        fontFamily: "Rubik",
        fontSize: "11px",
        fontWeight: 400,
        lineHeight: "15px",
        color: "#475569"
    },
    imagesBox: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 65px"
    },
    uploadFileBox: {
        border: "2px solid #000000",
        padding: "10px 25px",
        borderRadius: "3px",
        cursor: "pointer",
        width: "259px",
    },
    textUplodFile: {
        marginBottom: "0px",
        marginTop: "0px",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    textUploadFileContent: {
        marginBottom: "0px",
        marginTop: "0px",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
    },
}


// Customizable Area End
