// Customizable Area Start
import React, {Suspense}  from "react";
import { Box, IconButton, CircularProgress,  Button, useMediaQuery} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Appfooter as useStyles } from "./styles/Appfooter.web";
import { Link } from "react-router-dom";
import { Platform } from "../../utilities/src/models/Platform";
import GooglePlayBadge from "../assets/google-play-badge.png";
import AppStoreBadge from "../assets/../assets/app-store-badge.png";
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
import { StaticPage, footersData } from "./AppfooterController.web";
import { facebookLogo, instagramLogo, twitterLogo, youtubLogo } from "../../AdminWebsiteBuilder/src/assets";
import { Skeleton } from "@material-ui/lab";
import { configJSON } from "./AppfooterController.web";
import { WhatsApp } from "@material-ui/icons";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import theme from "../../utilities/src/theme";
import moment from "moment";

interface Props {
  phoneNumber: string;
  footerEmail: string;
  whatsAppNumber: string;
  whatsAppMessage: string;
  isWhatsappIntegration: boolean;
  socialMedias: Record<string, Platform>;
  applications: Record<string, Platform>;
  copyright: string;
  staticPages: StaticPage[];
  brandFooterFacebookUrl:string;
  brandFooterInstagramUrl:string;
  brandFooterXurl:string;
  brandFooterYoutubeurl:string;
  brandFootertogle:boolean;
  brandFooterContact:boolean;
  brandFooterApplink:boolean;
  brandFooteAppStoreUrl:string;
  brandFootePlayStoreUrl:string;
  copyRight:string;
  loading:boolean;
  buildCardID: string;
  footerData:footersData
}

const Appfooter: React.FC<Props> = ({
  phoneNumber,
  footerEmail,
  whatsAppNumber,
  whatsAppMessage,
  isWhatsappIntegration,
  footerData,
  staticPages,
  loading,
  brandFooterFacebookUrl,
  brandFooterInstagramUrl,
  brandFooterXurl,
  brandFooterYoutubeurl,
  brandFootertogle,
  brandFooterContact,
  brandFooterApplink,
  brandFooteAppStoreUrl,
  brandFootePlayStoreUrl,
  copyRight,
  buildCardID,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));
  const whatsAppUrl = `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${whatsAppMessage}`;
  const isPreviewMode = window.location.href.includes("/preview");
  return (
    <>
      <Box className={classes.tableContainerWrapper}>
        <Box className={classes.tableContainer}>
          <Box className={classes.table}>
            {staticPages.length > 0 &&
              <Box className={classes.column}>
                <Typography className={classes.header}>{configJSON.helpCenterText}</Typography>
                <Box className={classes.titleBox}>
                  <Box className={`${classes.infoWrapper} ${classes.helpCenter}`}>
                    {staticPages.map((item: StaticPage, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Link
                            onClick={() => {
                              mixpanel.track(`webcustomer_footer_navigation`, { type: "about_us", buildCardID: buildCardID });
                              setTimeout(() => {
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }, 200)
                            }}
                            to={ isPreviewMode
                              ?  "/preview/help-centre/" + item.attributes.page_slug
                              : "/help-centre/" + item.attributes.page_slug } className={classes.info}>
                            <Typography className={classes.sendMessage}>{item.attributes.title}</Typography>
                          </Link>
                        </React.Fragment>
                      )
                    })}
                  </Box>
                </Box>
              </Box>
            }
            <Box display="flex" flexDirection="column">
              <Box className={classes.column}>
                {brandFooterContact == true ? <>
                  <Typography className={classes.header}>{configJSON.contactUsText}</Typography>
                  <Box className={`${classes.infoWrapper} ${isSmallScreen ? classes.helpCenter : ""}`}>
                    <Link onClick={() => {
                      mixpanel.track(`webcustomer_footer_navigation`, { type: "about_us", buildCardID: buildCardID });
                      setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }, 200)
                    }}
                      to={isPreviewMode? "/AddContact/preview" : "/AddContact"} className={classes.info}>
                      <Typography className={classes.sendMessage}>{configJSON.sendAMssageText}</Typography>
                    </Link>
                    {(loading == true) ?
                      <Skeleton
                        width="120px"
                        height="24px"
                      /> : <>
                        {phoneNumber ? <Typography
                          className={classes.phoneNumber}
                          onClick={() => window.location.href = `tel:${phoneNumber}`}
                        >
                          {phoneNumber}
                        </Typography>
                          : ""}
                      </>
                    }
                  </Box>
                </> : ""}
              </Box>

              {brandFooterContact === true && footerEmail &&
              <Box className={classes.column}>
                <Typography className={classes.email} onClick={() => window.location.href = `mailto:${footerEmail}`}>Email</Typography>
                <Box className={`${classes.infoWrapper} ${isSmallScreen ? classes.helpCenter : ""}`}>

                  {(loading == true) ?
                    <Skeleton
                      width="120px"
                      height="24px"
                    /> : <>
                      {footerEmail ? <Typography
                        className={classes.phoneNumber}
                        onClick={() => window.location.href = `mailto:${footerEmail}`}
                      >
                        {footerEmail}
                      </Typography>
                        : ""}
                    </>
                  }
                </Box>
              </Box>
              }
            </Box>
            {(loading == true) ?
              <Box className={classes.imagesWrapper}>
                <Skeleton variant="rect" className={classes.imageSkeltonBox} />
                <Skeleton variant="rect" className={classes.imageSkeltonBox} />
              </Box> : <>
                {brandFooterApplink == true ? <>
                  {(brandFootePlayStoreUrl || brandFooteAppStoreUrl) ? (
                    <Box className={classes.column}>
                      <Typography className={classes.header}>{configJSON.downloadAppText}</Typography>
                      <Box className={classes.imagesWrapper}>
                        {brandFootePlayStoreUrl && (
                          <Suspense fallback={<CircularProgress />}>
                            <ImageCard
                              imageClassName={classes.image}
                              image={GooglePlayBadge}
                              onClick={() => {
                                mixpanel.track(`webcustomer_app_download`, { device: "android", buildCardID: buildCardID })
                                window.open(brandFootePlayStoreUrl)
                              }}
                              alt="image loading"
                            />
                          </Suspense>
                        )}
                        {brandFooteAppStoreUrl && (
                          <Suspense fallback={<CircularProgress />}>
                            <ImageCard
                              imageClassName={classes.image}
                              image={AppStoreBadge}
                              onClick={() => {
                                mixpanel.track(`webcustomer_app_download`, { device: "ios", buildCardID: buildCardID })
                                window.open(brandFooteAppStoreUrl)
                              }}
                              alt="image loading"
                            />
                          </Suspense>
                        )}
                      </Box>
                    </Box>
                  ) : ""}
                </> : ""}

              </>
            }
            {(loading == true) ?
            <Box className={classes.row}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="circle" width={40} height={40} />
            </Box> : <>
              {brandFootertogle == true ? <>
                {(brandFooterFacebookUrl || brandFooterInstagramUrl || brandFooterXurl || brandFooterYoutubeurl) ? (
                  <Box className={classes.followUsColumn}>
                    <Typography className={classes.header}>{configJSON.followUsOnText}</Typography>
                    <Box className={classes.row}>
                      {brandFooterFacebookUrl && (
                        <IconButton onClick={() => {
                          mixpanel.track(`webcustomer_footer_navigation`, { type: "facebook", buildCardID: buildCardID })
                          window.open(brandFooterFacebookUrl)
                        }}
                          className={classes.iconBtn}
                        >
                          <img src={facebookLogo} alt="facebook" className={classes.icon} width={"10.5px"} height={"20px"} />
                        </IconButton>
                      )}
                      {brandFooterInstagramUrl && (
                        <IconButton onClick={() => {
                          mixpanel.track(`webcustomer_footer_navigation`, { type: "instagram", buildCardID: buildCardID })
                          window.open(brandFooterInstagramUrl)
                        }}
                          className={classes.iconBtn}
                        >
                          <img src={instagramLogo} alt="instagram" className={classes.icon} width={"20px"} height={"20px"} />
                        </IconButton>
                      )}
                      {brandFooterXurl && (
                        <IconButton onClick={() => {
                          mixpanel.track(`webcustomer_footer_navigation`, { type: "twitter", buildCardID: buildCardID })
                          window.open(brandFooterXurl)
                        }}
                          className={classes.iconBtn}
                        >
                          <img src={twitterLogo} alt="twitter" className={classes.icon} width={"20.38px"} height={"16.57px"} />
                        </IconButton>
                      )}
                      {brandFooterYoutubeurl && (
                        <IconButton onClick={() => {
                          mixpanel.track(`webcustomer_footer_navigation`, { type: "youtube", buildCardID: buildCardID })
                          window.open(brandFooterYoutubeurl)
                        }}
                          className={classes.iconBtn}
                        >
                          <img src={youtubLogo} alt="youtube" className={classes.icon} width={"20px"} height={"16px"} />
                        </IconButton>
                      )}
                    </Box>

                  </Box>
                ) : ""}
              </> : ""}
            </>
          }
            {isWhatsappIntegration && (
          <Box>
              <IconButton onClick={() => {
                mixpanel.track(`webcustomer_footer_navigation`, { type: "youtube", buildCardID: buildCardID })
                window.open(whatsAppUrl)
              }}
              >
               <WhatsApp className={classes.whatappIcon} />
              </IconButton>
          </Box>
            )}
          </Box>
        </Box>
        <Typography component="div" className={classes.copyrights}>
          {copyRight.length>0 ? copyRight : `Copyright © ${moment().year()} ${footerData.attributes?.store_profile?.name} . All rights reserved.`}
        </Typography>
      </Box>
    </>
  );
};

export default Appfooter;
// Customizable Area End