import * as React from 'react';
// Customizable Area Start
import { useState, useEffect } from 'react';
import { NavItem, NavLink, Collapse } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SvgIcon } from "@material-ui/core";

interface RenderLinkProps extends RouteComponentProps {
    data: any
    tierType?: string
}

const RenderLink: any = withRouter((props: RenderLinkProps) => {
  const { data, location, tierType } = props;
  const haveSubLinks = Array.isArray(data.subLinks) && data.subLinks.length > 0;
  let isActive = false;

    if (data.link === "/") {
        isActive = location.pathname === data.link;
    } else {
        isActive = location.pathname.startsWith(data.link);

        if (data.link?.includes('/Gallery')) {
            isActive = isActive || (
                location.pathname.includes('/Gallery') &&
                (location.pathname.includes('/List') || location.pathname.includes('/ImageUpload'))
            );
        }
    }

  const initialOpen = !haveSubLinks
      ? false
      : data.subLinks.some((i: any) => {location.pathname.startsWith(i.link)});
  const [isOpen, toggleOpen] = useState(initialOpen);

    useEffect(() => {
        if (haveSubLinks) {
            if (isActive) {
                toggleOpen(true)
            }
        }
    }, [])
    useEffect(() => {
        if (haveSubLinks) {
            const isActive = data?.subLinks?.map((link:any)=>{return link?.link}).includes(location.pathname);
            if(isActive){
                toggleOpen(true)
            }
        }
    }, [location])

    const setAdminRoute = (link: string) => {
        const fullRoute = `/admin${link}`;
        localStorage.setItem('lastAdminRoute', fullRoute); 
      };
    return (
        <NavItem className='admin-nav__item'>
            <NavLink
                href={data.link ? '/admin'+data.link : '#'}
                className='admin-nav__link'
                onClick={
                    (e) => {
                        e.preventDefault();
                        if (haveSubLinks) {
                        toggleOpen(!isOpen)
                        } else if (data.link) {
                            setAdminRoute(data.link);  
                            props.history.push(data.link)
                        }
                    }
                }
                active={isActive}
            >
                <div className='d-inline-flex'>
                    {!data.icon ? <i className={`${data.iconName} admin-nav__lin-icon`}></i> : null}
                    {data.icon ? <SvgIcon component={data.icon} style={{ fill: "none", fontSize: 16 }} viewBox="0 0 18 18" /> : null}

                    &nbsp;
                    &nbsp;
                   
                    <span>{data.label}</span>
                </div>
                {
                    haveSubLinks && (
                        <i className={`icon-chevron-${isOpen ? 'up' : 'down'} admin-nav__angle-icon f-10`}></i>
                    )
                }
            </NavLink>
            {
                haveSubLinks && (
                    <Collapse isOpen={isOpen} className="mt-3">
                        {
                            data.subLinks.map((subLinkData: any) => <RenderLink tierType={tierType} data={subLinkData} />)
                        }
                    </Collapse>
                )
            }
        </NavItem>
    );
})

export default RenderLink;
// Customizable Area End