import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
// Customizable Area Start
import { Scrollbars } from "react-custom-scrollbars";
import { createRef } from "react"
import { getStorageData } from "../../../framework/src/Utilities";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { Message } from "../../../framework/src/Message";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import parser from "html-react-parser";
import { Location } from "../../utilities/src/models/Location";
import { parseLocation } from "../../utilities/src/helpers/utils";
import { HeaderMetadata } from "./ContactInformationController.web";
// Customizable Area End

export const configJSON = require("./config.js");

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    onTextChange?: (value: string) => void;
    createId: string;
    updateData: ProductPreviewContentInterface;
    isApiCalled: boolean;
    categoryData: Array<CategoryData>;
    selectedSection:string;
    productData: Array<ProductData>;
    statesData?: any;
    sectionCategoryTitle: { [key: string]: string }
    navigationItems: HeaderMetadata
    // Customizable Area End
  };

export interface S {
  // Customizable Area Start
  showProductPreview: ProductPreviewContentInterface;
  createId: string;
  sectionDataN: Array<sectionData>
  categoryData: Array<CategoryData>
  productData: Array<ProductData>
  mainId: string;
  CatagoryName: string;
  ProductName: string;
  testimonials: Record<string, string | number>[];
  locationDetails: Location | null;
  staticPagesData : Array<StaticData>;
  errorMessage: string;
  // Customizable Area End
}

// Customizable Area Start
export interface IStaffData {
  customer_name: string,
  description: string,
  selected: string,
  staffAttrs: {
      id: string,
      type: string,
      attributes: {
          id: 60,
          full_name: string,
          designation: string,
          slug: string,
          image: string,
          description: string,
      }
  },
}
export interface StaticData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: {
      questions: [
        {
          attributes: {
            title: string;
            content: string;
            status: boolean;
          }
        }
      ]
    },
    active: boolean;
    position: number;
    page_type: {
      id: number;
      name: string;
    },
    page_slug: string;
    created_at: string;
    updated_at: string;
  }
}

export interface StaticDataArray {
  data: Array<StaticData>;
}

export interface Section {
  selected: string;
  serviceAttrs: {
    id: string;
    type: string;
    attributes: {
      title: string;
      description:string
      duration: number;
      status: boolean;
      discount_option: boolean;
      discount: number;
      slug: string;
      price: number;
      currency: {
        id: number;
        name: string;
        symbol: string
      },
      actual_price: number;
      images: string
    }
  }
}

export type sectionData = {
  id: string;
  type: string;
  attributes: {
    banner_background_colour?: string;
    section_type: string;
    section_name: string;
    banner_url: string;
    mobile_banner_url: string;
    desktop_banner_url: string,
    is_deleted: boolean,
    metadata: Object,
    desktop_banner_variants: {
      url: string;
      webp_url: string;
      768: string;
      1280: string;
      1920: string;
    },
    mobile_banner_variants: {
      url: string;
      webp_url: string;
      360: string;
      414: string;
      601: string;
    },
    template_details?: {
      id: number;
      name: string;
      template_type: string;
      template_type_name: string;
    }
  }
};
export interface CategoryData {
  category_image: string,
  category_name: string
}
export interface ProductData {
  product_name: string,
  product_image: string,
  new_rate: string,
  old_rate: string,
  currency: string,
  discount: string
}
export interface ProductPreviewContentInterface {
  [x: string]: any;

  footer: { [key: string]: number | boolean | string }
  sections: {
    data: Array<sectionData>
  };
  header: {
    storeName: string,
    isStoreNameEnabled: boolean,
    isLogoUploaded?: boolean,
    selectedLogo?: any,
    isMobileMenuOpen?: boolean,
    navigationMenuOptions?: any,
    selectedFavicon?:any,
    selectedLogoName?:string,
    selectedFaviconName?:string,
    isFaviconUploaded?: boolean;
    templateId?: number;
    templateDetails?: {
      id?: number;
      name?: string;
      templateType?: string;
    }
  };
}



export interface SS {
  id: string;
}
// Customizable Area End

class ProductPreviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollRef: React.RefObject<Scrollbars>;
  getCustomerFeedbackApiCallId: string = "";
  getLocationApiCallId: string = "";
  getStaticApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      showProductPreview: {
        header: {
          storeName: "",
          isStoreNameEnabled: false,
        },

        sections: {
          data: [

          ]
        },
        footer: {
          phone_number: 5656565656,
          copyright: "",
          facebook_url: "",
          x_url: "",
          youtube_url: "",
          linkedin_url: "",
          appstore_url: "",
          playstore_url: "",
          instagram_url: "",
          design_id: "",
          is_social_media: false,
          is_app_link: false,
          is_contact: false
        },
        isLocationTrigged: false,
        isTestimonialTrigged: false,

      },
      createId: "",
      sectionDataN: [],
      categoryData: [],
      productData: [],
      mainId: "",
      CatagoryName: "Categories",
      ProductName: "Products Display",
      testimonials: [],
      locationDetails: null,
      staticPagesData : [],
      errorMessage: ""
    }


    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    this.handleCustomerFeedbackResponse(message);   
  };

  async componentDidMount() {
    this.getCustomerFeedbacks();
    this.getLocation();
    this.getStaticPages();
    let id = await getStorageData("id")
    this.setState({
      mainId: id
    })
  }
  scrollToTarget = () =>{
    let section;
    this.props.selectedSection

    if (this.props.selectedSection === 'header') {
        section = "headerUnique";
    } else if (this.props.selectedSection === 'footer') {
        section = "footerUnique";
    } else if (this.props.selectedSection === 'section') {
        section = "headerUnique";
    } else if (this.props.selectedSection.includes('section')) {
      section = this.props.selectedSection;
    } else {
        section = "";
    }
    let targetElement = document.getElementById(section);

    if (targetElement) {
      // Scroll to the target element
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  getBannerTextPosition = (section: any, platform: 'desktop' | 'mobile') => {
    let positions = ["center", "center"];
    const textPosition = section[platform === 'desktop' ? 'desktopBannerMetadata' : 'mobileBannerMetadata'].text_position;
    
    if (textPosition === "Top") positions = ["center", "flex-start"];
    else if (textPosition === "Top Left") positions = ["flex-start", "flex-start"];
    else if (textPosition === "Top Right") positions = ["flex-end", "flex-start"];
    else if (textPosition === "Center") positions = ["center", "center"];
    else if (textPosition === "Mid Left") positions = ["flex-start", "center"];
    else if (textPosition === "Mid Right") positions = ["flex-end", "center"];
    else if (textPosition === "Bottom") positions = ["center", "flex-end"];
    else if (textPosition === "Bottom Right") positions = ["flex-end", "flex-end"];
    else if (textPosition === "Bottom Left") positions = ["flex-start", "flex-end"];
    
    return { justifyContent: positions[0], alignItems: positions[1] }
  }

  getCustomerFeedbacks = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    this.getCustomerFeedbackApiCallId = BlockHelpers.callApi({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCustomerFeedbackEndPoint + "?per_page=100",
      header,
    });
  };

  handleCustomerFeedbackResponse = (message: Message) => {
    if(getName(MessageEnum.RestAPIResponceMessage) !== message.id){
      return;
    }
    const [responseJson, apiRequestCallId] = getResponseVariables(message);   
    if(this.getCustomerFeedbackApiCallId === apiRequestCallId){ 
      const list = responseJson.data.filter((item: {attributes: {is_active: boolean}}) => item.attributes.is_active)
      list.sort((elem: { attributes: { position: number; }; }, curElem: { attributes: { position: number; }; }) => elem.attributes.position - curElem.attributes.position)
      this.setState({
        testimonials: list.map((item: { attributes: { customer_name: string; description: string; }; }) => ({
          name: item.attributes?.customer_name,
          rating: 5,
          description: parser(item.attributes?.description)
        }))
      })
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLocationApiCallId != null &&
      this.getLocationApiCallId ===
      apiRequestCallId
    ) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetLocationResponse(responseJson);
      } else {
        this.setState({ errorMessage: "An error has occurred. Please try again later." });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStaticApiCallId != null &&
      this.getStaticApiCallId ===
      apiRequestCallId
    ) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetStaticPageResponse(responseJson);
      } else {
        this.setState({ errorMessage: "An error has occurred. Please try again later." });
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  getLocation = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    this.getLocationApiCallId = BlockHelpers.callApi({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getLocationEndPoint,
      header,
    });
  };

  getStaticPages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    this.getStaticApiCallId = BlockHelpers.callApi({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getStaticPageApiEndpoint,
      header,
    });
  };

  handleGetLocationResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson.data;

    this.setState({
      locationDetails: parseLocation(response)
    });
  };

  handleGetStaticPageResponse = (responseJson:StaticDataArray) => {
    this.setState({staticPagesData: responseJson.data});
  };

  getShortDescription = (description:string) => {
    if (description?.length > 120) {
      return `${description?.slice(0, 120)}...`;
    }
    return description;
  };
  // Customizable Area End
}

export default ProductPreviewController;
